export default {
  name: 'Card',

  props: {
    disabled: {
      type: Boolean,
      default: undefined,
      required: false,
    },
    href: {
      type: String,
      default: undefined,
      required: false,
    },
    interactive: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    isInteractiveLink() {
      return this.interactive && this.href;
    },
    listenersSansClick() {
      const { click, ...others } = this.$listeners;
      return others;
    },
    tag() {
      if (!this.interactive) {
        return 'div';
      }

      return this.href ? 'a' : 'button';
    },
  },

  methods: {
    handleClick() {
      if (this.interactive && !this.disabled) {
        this.$emit('click');
      }
    },
  },
};
