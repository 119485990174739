import '../initializers/initialize-csp';

import '../../stylesheets/application.scss';

import initializeAppModules from '../initializers/application/initialize-app-modules';
import initializeAxios from '../initializers/application/initialize-axios';
import initializeFormulate from '../initializers/application/initialize-formulate';
import initializeGlobals from '../initializers/application/initialize-globals';
import initializeSentry from '../initializers/application/initialize-sentry';
import initializeToasts from '../initializers/application/initialize-toasts';
import initializeVue from '../initializers/application/initialize-vue';
import initializeVueModules from '../initializers/application/initialize-vue-modules';

initializeSentry();
initializeGlobals();
initializeAxios();

document.addEventListener('DOMContentLoaded', () => {
  initializeVue();
  initializeFormulate();
  initializeToasts();
  initializeAppModules();
  initializeVueModules();
});
