import { debounce } from 'lodash-es';

import TooltipIcon from '@workshop/baja/assets/icons/icon-tooltip.svg';
import Button from 'app/core/button/Button.vue';
import { PopperProps } from 'app/core/popper/Popper';
import Popper from 'app/core/popper/Popper.vue';

import { ButtonProps } from '../button/Button';

export const TooltipProps = {
  textAlignments: ['center', 'left'],
  variants: ['light', 'dark'],
};

let nextTooltipId = 0;

export default {
  name: 'Tooltip',

  components: {
    Button,
    TooltipIcon,
    Popper,
  },

  props: {
    /** The arrow styles which determines whether to render the unstyled popper arrow that tails the content overlay. */
    arrowClass: {
      type: String,
      default: undefined,
    },
    buttonSize: {
      type: String,
      default: 'sm',
      required: false,
      validator: (val) => ButtonProps.sizes.includes(val),
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    keepHover: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'top',
      validator: (val) => PopperProps.placements.includes(val),
    },
    placementClasses: {
      type: [String, Array, Object],
      default: undefined,
    },
    strategy: {
      type: String,
      default: 'absolute',
      validator: (val) => PopperProps.strategies.includes(val),
    },
    variant: {
      type: String,
      default: 'dark',
      validator: (val) => TooltipProps.variants.includes(val),
    },
    portalTarget: {
      type: String,
      default: undefined,
    },
    textAlign: {
      type: String,
      default: 'center',
      validator: (val) => TooltipProps.textAlignments.includes(val),
    },
    tooltipClasses: {
      type: [String, Boolean],
      default: undefined,
    },
    triggerClasses: {
      type: [String, Boolean, Array],
      default: undefined,
    },
    offsetSkidding: {
      type: Number,
      default: undefined,
    },
    offsetDistance: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number,
      default: undefined,
    },
    full: {
      type: Boolean,
      default: false,
    },
    interactiveTrigger: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tooltipId: `tooltip-${++nextTooltipId}`,
      visible: false,
      hasDefaultSlot: !!this.$slots.default,
      toggleTooltip: debounce(
        function _toggleTooltip(show) {
          this.visible = !!show;
          this.$emit(this.visible ? 'show' : 'hide');
        },
        100,
        { leading: false, trailing: true }
      ),
    };
  },

  computed: {
    textAlignClass() {
      return `text-${this.textAlign}`;
    },

    getArrowClass() {
      if (this.arrowClass) {
        return this.arrowClass;
      }
      if (this.variant === 'light') {
        return 'bg-white';
      }
      return 'bg-gray-600';
    },

    getTooltipClasses() {
      if (this.tooltipClasses) {
        return this.tooltipClasses;
      }
      if (this.variant === 'light') {
        return 'rounded bg-white p-4 text-sm';
      }
      return 'rounded bg-gray-600 p-2 text-sm text-white';
    },
  },

  mounted() {
    this.hasDefaultSlot = !!this.$slots.default;
  },

  methods: {
    showTooltip(keepHover) {
      this.$emit('hover');
      if (keepHover) {
        this.toggleTooltip(true);
      }
    },

    hideTooltip() {
      this.toggleTooltip(false);
    },
  },
};
