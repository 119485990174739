import {
  currentCompany,
  currentUser,
} from 'lib/document-helpers/document-meta';

export default function initializeGlobals() {
  window.Workshop ||= {};
  window.Workshop.currentUser = currentUser();
  window.Workshop.currentCompany = currentCompany();
}
