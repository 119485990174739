import { exampleIndexPath, examplePath } from 'routes';

import ManageExample from './manage-example/ManageExample.vue';
import PreviewExample from './preview-example/PreviewExample.vue';

export default {
  name: 'example',
  label: 'Example',
  type: 'delivery',
  manageComponent: ManageExample,
  previewComponent: PreviewExample,
  indexPath: exampleIndexPath,
  path: examplePath,
  allowFeaturedImage: false,
  allowCustomMessage: true,
  customMessageMaxLength: 320,
  hexColor: '#0056D6',
};
