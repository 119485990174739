import { bamboohrIndexPath, bamboohrPath } from 'routes';

import ManageMerge from './manage-merge/ManageMerge.vue';

export default async (name) => {
  // This build merge app helper is used to instantiate all of the different merge apps
  // They all have different paths that are mapped to the same controller.
  // To avoid importing the _entire_ routes file, we're just using one base example,
  // bamboohr, to get the route and search/replace it with the built provider name
  const indexPath = () => {
    const replace = bamboohrIndexPath();
    return replace.replace('bamboohr', name);
  };

  const path = (id) => {
    const replaceId = 'replaceId';
    return bamboohrPath(replaceId)
      .replace('bamboohr', name)
      .replace(replaceId, id);
  };

  return {
    name,
    label: name,
    type: 'data',
    manageComponent: ManageMerge,
    indexPath,
    path,
  };
};
