import { debounce } from 'lodash-es';

import SearchIcon from '@workshop/baja/assets/icons/icon-search.svg';
import Popper from 'app/core/popper/Popper.vue';
import SearchInput from 'app/core/search-input/SearchInput.vue';

import FilterSelectOption from './filter-select-option/FilterSelectOption.vue';

export default {
  name: 'FilterSelect',

  components: {
    FilterSelectOption,
    Popper,
    SearchIcon,
    SearchInput,
  },

  props: {
    allowFiltering: {
      type: Boolean,
      default: true,
    },
    context: {
      type: Object,
      required: true,
    },
    inputClass: {
      type: String,
      default: undefined,
    },
    mapFilterFunction: {
      type: Function,
      default: undefined,
    },
    mapLabelFunction: {
      type: Function,
      default: (option) => option?.label || option,
    },
    mapValueFunction: {
      type: Function,
      default: (option) => option.value,
    },
    maxWidth: {
      type: Number,
      default: undefined,
    },
    portalTarget: {
      type: String,
      default: undefined,
    },
    wrapperClass: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      debounceInputChange: debounce(this.handleInputChange, 300),
      dropdownActive: false,
      inputValue: '',
      query: '',
      selectedIndex: -1,
    };
  },

  computed: {
    filteredOptions() {
      if (this.allowFiltering) {
        const fn = this.mapFilterFunction || this.mapLabelFunction;
        return this.context.options.filter((option) =>
          fn(option)
            ?.toLowerCase()
            .includes(this.query?.toLowerCase() || '')
        );
      }
      return this.context.options;
    },

    selection() {
      if (this.filteredOptions[this.selectedIndex]) {
        return this.filteredOptions[this.selectedIndex];
      }
      return undefined;
    },

    showDropdown() {
      return !!this.dropdownActive;
    },

    model() {
      return this.context.model;
    },
  },

  watch: {
    model: {
      immediate: true,
      handler(value) {
        const matchedOption = this.context.options.find(
          (option) => this.mapValueFunction(option) === value
        );
        this.inputValue = this.mapLabelFunction(matchedOption);

        this.closeDropdown();
      },
    },
  },

  beforeDestroy() {
    this.closeDropdown();
  },

  methods: {
    handleInputFocus(event) {
      event.target.select();

      if (!this.model) {
        this.$emit('input-change', this.query);
      }

      if (this.dropdownActive) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },

    handleInputChange(event) {
      // only respond to user input (not changes caused by model binding)
      if (event?.type !== 'search') {
        this.query = event;
      }
    },

    handleItemClick(item) {
      if (item) {
        this.$emit('selected', this.mapValueFunction(item), item);
        this.inputValue = this.mapLabelFunction(item);
        this.context.model = this.mapValueFunction(item);
        this.closeDropdown();
      }
    },

    handleEnterPress() {
      if (this.selection) {
        this.handleItemClick(this.selection);
      } else {
        this.closeDropdown();
      }
    },

    increment() {
      const { length } = this.filteredOptions;
      this.selectedIndex = length
        ? Math.min(this.selectedIndex + 1, length - 1)
        : -1;
    },

    decrement() {
      const { length } = this.filteredOptions;

      if (!length) {
        this.selectedIndex = -1;
      } else if (this.selectedIndex === -1) {
        this.selectedIndex = length - 1;
      } else {
        this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
      }
    },

    openDropdown() {
      if (!this.dropdownActive) {
        document.addEventListener('click', this.clickListener);
        this.dropdownActive = true;

        if (this.allowFiltering) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.searchInput.$refs.searchInput.focus();
            }, 0);
          });
        }
      }
    },

    closeDropdown() {
      if (this.dropdownActive) {
        this.dropdownActive = false;
        document.removeEventListener('click', this.clickListener);

        this.query = '';
      }
    },

    clickListener(event) {
      const clickInsideDropdown = this.$refs.filterSelect.contains(
        event.target
      );
      const clickInsideSearch = this.allowFiltering
        ? this.$refs.searchInput.$refs.searchInput.contains(event.target)
        : false;
      if (!clickInsideDropdown && !clickInsideSearch) {
        this.closeDropdown();
      }
    },
  },
};
