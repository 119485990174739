<template>
  <Link
    :unstyled="true"
    class="sort-header"
    :class="[
      ascending ? 'sort-header--asc' : 'sort-header--desc',
      { 'sort-header--active': isActiveSort },
    ]"
    @click="emitSort(headerSortValue)"
  >
    <slot />
    <template #append>
      <div class="sort-header__arrows">
        <SortArrowIcon class="up-arrow" />
        <SortArrowIcon class="down-arrow" />
      </div>
    </template>
  </Link>
</template>

<script>
import SortArrowIcon from '@workshop/baja/assets/icons/icon-filter-arrow.svg';
import Link from 'app/core/link/Link.vue';

export default {
  name: 'SortHeader',

  components: {
    Link,
    SortArrowIcon,
  },

  props: {
    sortingInfo: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ascending() {
      return this.sortingInfo.sortDirection === 'asc';
    },

    descending() {
      return this.sortingInfo.sortDirection === 'desc';
    },

    headerSortValue() {
      return this.header.sortColumn || this.header.title.toLowerCase();
    },

    isActiveSort() {
      // if the currently sorted column in the passed sortingInfo (probably from the store)
      // is the same as the current header, it should be the active sort and use SortHeader
      return this.sortingInfo.sortBy === this.headerSortValue;
    },
  },

  methods: {
    emitSort(sort) {
      const sortEvent = {
        sortDirection:
          sort === this.sortingInfo.sortBy &&
          this.sortingInfo.sortDirection === 'asc'
            ? 'desc'
            : 'asc',
        sortBy: sort,
      };

      this.$emit('sort-event', sortEvent);
    },
  },
};
</script>

<style lang="scss" scoped src="./SortHeader.scss" />
