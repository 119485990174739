<template>
  <div v-if="showIfEnabled || showIfDisabled">
    <slot></slot>
  </div>
</template>

<script>
import { isUndefined } from 'lodash-es';

export default {
  name: 'FeatureGate',

  props: {
    featureEnabled: {
      type: String,
      default: undefined,
    },
    featureDisabled: {
      type: String,
      default: undefined,
    },
    featureType: {
      type: String,
      required: true,
      validator: (value) => ['platform', 'email', 'sms'].includes(value),
    },
  },

  computed: {
    showIfEnabled() {
      return (
        !isUndefined(this.featureEnabled) &&
        this.isFeatureEnabled(this.featureEnabled)
      );
    },

    showIfDisabled() {
      return (
        !isUndefined(this.featureDisabled) &&
        !this.isFeatureEnabled(this.featureDisabled)
      );
    },
  },

  created() {
    if (
      !isUndefined(this.featureEnabled) &&
      !isUndefined(this.featureDisabled)
    ) {
      throw new Error(
        'Ope! FeatureGates cannot have both a featureEnabled and featureDisabled prop.'
      );
    }
  },

  methods: {
    isFeatureEnabled(feature) {
      if (this.featureType === 'platform') {
        return this.currentCompany.platformFeatures.includes(feature);
      }

      if (this.featureType === 'email') {
        return this.currentCompany.emailFeatures.includes(feature);
      }

      return this.currentCompany.smsFeatures.includes(feature);
    },
  },
};
</script>
