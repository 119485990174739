import Vue from 'vue';

// Vue Plugin that adds current user and current company
export default {
  install: () => {
    Vue.mixin({
      computed: {
        currentUser() {
          return window.Workshop.currentUser;
        },
        currentCompany() {
          return window.Workshop.currentCompany;
        },
        scopeOfViewEnabled() {
          return this.currentCompany.enabledTrebuchets.includes(
            'scope_of_view'
          );
        },
      },
    });
  },
};
