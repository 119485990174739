import { slackPath, slacksPath } from 'routes';

import ConfigureSlack from './ConfigureSlack.vue';
import ManageSlack from './manage-slack/ManageSlack.vue';
import PreviewSlack from './preview-slack/PreviewSlack.vue';

export default {
  name: 'slack',
  label: 'Slack',
  type: 'delivery',
  manageComponent: ManageSlack,
  indexPath: slacksPath,
  path: slackPath,
  configureComponent: ConfigureSlack,
  previewComponent: PreviewSlack,
  allowFeaturedImage: false,
  allowCustomMessage: true,
  customMessageMaxLength: 320,
  hexColor: '#FFC300',
};
