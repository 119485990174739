export default {
  name: 'SingleOtpInput',

  props: {
    context: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    value: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
    },
    shouldAutoFocus: {
      type: Boolean,
    },
    inputType: {
      type: String,
      default() {
        return 'tel';
      },
    },
  },

  data() {
    return {
      model: this.value || '',
    };
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue;
      }
    },

    focus(newFocusValue, oldFocusValue) {
      // Check if focusedInput changed
      // Prevent calling function if input already in focus
      if (oldFocusValue !== newFocusValue && this.$refs.input && this.focus) {
        this.$refs.input.focus();
        this.$refs.input.select();
      }
    },
  },

  mounted() {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus();
    }
  },

  methods: {
    handleChange() {
      return this.$emit('change', this.model);
    },

    handleKeyDown(event) {
      this.$emit('keydown', event);
    },

    handlePaste(event) {
      return this.$emit('paste', event);
    },

    handleFocus() {
      this.$refs.input.select();
      return this.$emit('focus');
    },

    handleBlur(event) {
      return this.$emit('blur', event);
    },
  },
};
