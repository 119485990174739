/* eslint-disable no-param-reassign */

/**
 * Directive to hide the element and collapse its width down to 0.
 * This is useful when needing the height of multiple elements to be retained even when toggle visibity between them.
 * @example <div v-hidden-collapse="isExpanded" />
 */
export const vHiddenCollapseDirective = {
  install(Vue) {
    Vue.directive('hidden-collapse', (el, binding) => {
      const { value } = binding;

      if (value) {
        el.style.visibility = 'hidden';
        el.style.width = 0;
        el.setAttribute('aria-hidden', 'true');
      } else {
        el.style.visibility = 'visible';
        el.style.width = '';
        el.removeAttribute('aria-hidden');
      }
    });
  },
};
