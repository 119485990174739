<template>
  <FormulateForm
    v-slot="{ isLoading }"
    v-model="formData"
    class="max-w-xl"
    name="editSlack"
    @submit="submitHandler"
  >
    <FormulateInput
      label="Default Channel"
      validation-name="Default Channel"
      type="autocomplete"
      behavior="select"
      name="defaultChannel"
      placeholder="Search for slack channel..."
      search-query="channel_name_cont"
      :search-endpoint="searchEndpoint"
    />
    <FormulateInput
      type="text"
      name="botName"
      label="Name of your bot"
      validation="required"
      help="This name will be displayed as the bot’s name inside of Slack. We recommend something generic like “Emails”."
      validation-name="Bot name"
    />
    <div>
      <FormulateInput
        v-model="bot"
        name="botImage"
        type="image"
        label="Bot Avatar"
        validation-name="Bot Image"
        validation="mime:image/jpeg,image/png,image/gif,image/heic"
        :file-class="['single-column-overflow']"
        class="mb-1"
        @file-removed="isDefaultImage = false"
        @file-upload-complete="isDefaultImage = false"
      />
      <Button
        v-if="!isDefaultImage"
        size="sm"
        variant="tertiary"
        class="-mx-2"
        @click="setDefaultBotImage"
      >
        Reset bot avatar to default
      </Button>
    </div>
    <Button type="submit" :is-loading="isLoading || isSaving" class="mt-9">
      Save
    </Button>
  </FormulateForm>
</template>

<script>
import axios from 'axios';

import Button from 'app/core/button/Button.vue';
import WorkshopMark from 'assets/images/WorkshopMark.png';
import { searchChannelsPath, slackPath } from 'routes';
import {
  deserializeFileUpload,
  serializeFileUpload,
} from 'utils/model-data/file-upload';

export default {
  name: 'ManageSlackForm',

  components: {
    Button,
  },

  props: {
    slack: {
      type: Object,
      required: true,
    },
    deliveryMethod: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isDefaultImage: false,
      formData: null,
      defaultBotImage: { url: WorkshopMark, signedId: 'Default' },
    };
  },

  computed: {
    searchEndpoint() {
      return searchChannelsPath({
        search: {
          appId: this.deliveryMethod.id,
          appType: this.deliveryMethod.courier,
        },
      });
    },
  },

  watch: {
    slack: {
      immediate: true,
      handler(val) {
        const botImage = deserializeFileUpload(val, 'botImage');
        this.formData = {
          defaultChannel:
            val.defaultChannel || this.deliveryMethod.defaultChannel,
          botName: val.botName,
          botImage,
        };
        this.isDefaultImage = !!val.defaultImage;
        this.bot = this.formData.botImage;
      },
    },
  },

  methods: {
    searchChannelsPath,

    async submitHandler(data) {
      const slack = {
        defaultChannel: data.defaultChannel.id,
        botName: data.botName,
        botImage: serializeFileUpload(data.botImage),
      };
      const payload = {
        slack,
      };

      this.isSaving = true;
      try {
        await axios.patch(slackPath(this.slack.id), payload);
        this.$toast('Slack settings saved successfully', { type: 'success' });
      } catch (err) {
        this.$formulate.handle(err, 'editSlack');
      } finally {
        this.isSaving = false;
      }
    },

    setDefaultBotImage() {
      this.isDefaultImage = true;
      const fileData = {
        path: { url: WorkshopMark, signedId: 'Default' },
        previewData: WorkshopMark,
        name: 'WorkshopMark.png',
        type: 'image/png',
      };
      this.bot.files = [
        {
          complete: false,
          error: false,
          ...fileData,
          file: { ...fileData },
          justFinished: false,
          progress: false,
          removeFile: () => {
            this.bot.files = [];
          },
          uuid: '',
        },
      ];
      this.bot.uploadPromise = Promise.resolve({});
    },
  },
};
</script>
