import { ukgProIndexPath, ukgProPath } from 'routes';

import ManageUkgPro from './manage-ukg-pro/ManageUkgPro.vue';

export default {
  name: 'ukg_pro',
  label: 'UKG Pro',
  type: 'data',
  manageComponent: ManageUkgPro,
  indexPath: ukgProIndexPath,
  path: ukgProPath,
};
