import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import FadeTransition from 'app/brand/transitions/FadeTransition.vue';

export const ButtonProps = {
  sizes: ['xs', 'sm', 'md', 'lg'],
  colors: ['default', 'light', 'gray', 'danger'],
  variants: ['primary', 'secondary', 'tertiary'],
  types: ['button', 'submit'],
};

export default {
  name: 'Button',

  components: {
    LoadingIcon,
    FadeTransition,
  },

  props: {
    size: {
      type: String,
      default: 'md',
      required: false,
      validator: (val) => ButtonProps.sizes.includes(val),
    },
    color: {
      type: String,
      default: 'default',
      required: false,
      validator: (val) => ButtonProps.colors.includes(val),
    },
    variant: {
      type: String,
      default: 'primary',
      required: false,
      validator: (val) => ButtonProps.variants.includes(val),
    },
    type: {
      type: String,
      default: 'button',
      required: false,
      validator: (val) => ButtonProps.types.includes(val),
    },
    href: {
      type: String,
      default: undefined,
      required: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    disabledVisually: {
      type: Boolean,
      default: undefined,
    },
    circle: {
      type: Boolean,
      default: undefined,
    },
  },

  computed: {
    tag() {
      if (this.disabled && this.href) return 'div';
      return this.href ? 'a' : 'button';
    },

    hasAppendOrPrepend() {
      return !!this.$slots.prepend || !!this.$slots.append;
    },

    filteredListeners() {
      const { click, ...rest } = this.$listeners;
      return rest;
    },
  },

  methods: {
    prefixClass(value) {
      return value ? `button--${value}` : undefined;
    },

    handleClick(event) {
      if (this.disabled || this.isLoading) {
        event.preventDefault();
      } else {
        this.$emit('click', event);
      }
    },
  },
};
