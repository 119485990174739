import { sftpIndexPath, sftpPath } from 'routes';

import ManageSftp from './manage-sftp/ManageSftp.vue';

export default {
  name: 'sftp',
  label: 'SFTP',
  type: 'data',
  manageComponent: ManageSftp,
  indexPath: sftpIndexPath,
  path: sftpPath,
};
