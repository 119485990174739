<template>
  <label
    :id="context.id + '_label'"
    :for="context.id"
    :class="context.classes.label"
  >
    <template v-if="tooltip">
      <span class="align-middle">{{ context.label }}</span>
      <span v-if="showOptional" class="align-middle italic text-gray-450"
        >(Optional)</span
      >
      <Tooltip class="-my-1 align-middle" :width="tooltipWidth">
        <template #default>
          <InfoOutlineIcon class="w-4 text-blue-500" />
        </template>
        <template #overlay>{{ tooltip }}</template>
      </Tooltip>
    </template>
    <template v-else>
      <span class="align-middle">{{ context.label }}</span>
      <span v-if="showOptional" class="align-middle italic text-gray-450"
        >(Optional)</span
      >
    </template>
  </label>
</template>

<script>
import InfoOutlineIcon from '@workshop/baja/assets/icons/icon-info-outline.svg';
import Tooltip from 'app/core/tooltip/Tooltip.vue';

export default {
  name: 'FormLabel',

  components: {
    InfoOutlineIcon,
    Tooltip,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },

    tooltip: {
      type: String,
      default: undefined,
    },

    tooltipWidth: {
      type: Number,
      default: 300,
    },

    showOptional: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
