<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <div v-if="ukgPro.status === 'pending_activation' || showSetup">
      <Title :level="3">Set Up UKG Pro Syncing</Title>
      <p class="mb-4 mt-2">
        To begin, please get your credentials and paste them below. In UKG Pro,
        make sure you have at least the "Personnel Integration" permissions set
        to 'view'
      </p>

      <FormulateForm
        :form-errors="formErrors"
        name="authorizeUkgPro"
        class="mt-4 w-full"
        @submit="authorizeUkgPro"
      >
        <FormulateInput
          type="text"
          name="apiUrl"
          label="API URL"
          validation="required"
          class="w-full"
          autocomplete="off"
        />

        <FormulateInput
          type="text"
          name="apiKey"
          label="API Key"
          validation="required"
          class="w-full"
          autocomplete="off"
        />

        <FormulateInput
          type="text"
          name="authUsername"
          label="UKG Pro Username"
          validation="required"
          class="w-full"
          autocomplete="off"
        />

        <FormulateInput
          type="text"
          name="authPassword"
          label="UKG Pro Password"
          validation="required"
          class="w-full"
          autocomplete="off"
        />
        <Button type="submit" :is-loading="isAuthorizing" class="mt-2">
          Connect to UKG Pro
        </Button>
      </FormulateForm>
    </div>
    <div v-if="ukgPro.status === 'active' && !showSetup">
      <Title :level="3">UKG Pro Syncing</Title>
      <ContentPlaceholder class="my-10">
        <template #title>
          <div class="flex flex-col items-center">
            UKG Pro is connected!
            <p class="mt-2 font-normal text-gray-500">
              Last Synced {{ lastSyncedAgo }}
            </p>

            <Button
              class="mt-4 w-full md:w-auto"
              data-testid="generate-api-key-button"
              variant="secondary"
              @click="displaySetupInputs"
            >
              Manage API Configuration
            </Button>
          </div>
        </template>
      </ContentPlaceholder>
    </div>
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import axios from 'axios';
import * as timeago from 'timeago.js';
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Button from 'app/core/button/Button.vue';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';
import Title from 'app/core/title/Title.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';
import { ukgProPath } from 'routes';

export default {
  name: 'ManageUkgPro',

  components: {
    AppPlaceholder,
    Button,
    ContentPlaceholder,
    LoadingIcon,
    Title,
  },

  data() {
    return {
      formErrors: [],
      showSetup: false,
      isAuthorizing: false,
    };
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      ukgPro: 'connectedData',
    }),
    lastSyncedAgo() {
      return timeago.format(this.ukgPro.lastSyncedAt);
    },
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },

    displaySetupInputs() {
      this.showSetup = true;
    },

    async authorizeUkgPro(data) {
      this.isAuthorizing = true;
      try {
        await axios.patch(ukgProPath(this.ukgPro.id), {
          ukgPro: data,
        });
        this.getConnected({ type: this.app.type });
      } catch {
        this.formErrors = [
          'Could not connect to UKG Pro with provided credentials.',
        ];
      }
      this.isAuthorizing = false;
    },
  },
};
</script>
