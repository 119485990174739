<template>
  <tr
    class="print:break-inside-avoid print:break-after-auto"
    :class="{
      hover: hover && !disabled,
      group: hover,
      'dropdown-open': dropdownOpen,
      'cursor-pointer': hover && !disabled,
      '!cursor-not-allowed': hover && disabled,
    }"
    :tabindex="hover ? '0' : '-1'"
    @click="hover ? handleClick($event) : undefined"
    @keypress.self.enter="handleClick"
    @keypress.self.space="handleClick"
  >
    <slot />
  </tr>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    dropdownOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleClick(e) {
      const selection = window.getSelection().toString();

      // Fire table row actions only if it was a keypress
      // or a click with no currently selected text
      if (e.type !== 'click' || (e.type === 'click' && !selection)) {
        this.$emit('click', e);
      }
    },
  },
};
</script>
