import { isNumber } from 'lodash-es';

export const GradientColors = ['gray', 'white'];

export default {
  name: 'ScrollContainer',

  props: {
    height: {
      type: Number,
      required: false,
      default: undefined,
    },

    width: {
      type: Number,
      required: false,
      default: undefined,
    },

    gradientColor: {
      type: String,
      required: false,
      default: 'gray',
      validator: (val) => GradientColors.includes(val),
    },

    containerClasses: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      fullContentSize: 0,
      overflowSize: 0,
      currentScroll: 0,
      scrolled: 0,
    };
  },

  mounted() {
    this.setScrollValues();
    this.addScrollListener();
  },

  watch: {
    height() {
      this.setScrollValues();
    },

    width() {
      this.setScrollValues();
    },
  },

  computed: {
    fromClass() {
      switch (this.gradientColor) {
        case 'white':
          return 'from-white';
        default:
          return 'from-gray-50';
      }
    },

    hasHorizontalScrollBar() {
      return isNumber(this.width) && isNumber(this.fullContentSize)
        ? this.fullContentSize > Math.ceil(this.width)
        : false;
    },

    hasVerticalScrollBar() {
      return isNumber(this.height) && isNumber(this.fullContentSize)
        ? this.fullContentSize > Math.ceil(this.height)
        : false;
    },

    pixelWidth() {
      return isNumber(this.width) ? `${this.width}px` : undefined;
    },

    pixelHeight() {
      return isNumber(this.height) ? `${this.height}px` : undefined;
    },
  },

  methods: {
    addScrollListener() {
      this.$refs.content.addEventListener('scroll', this.scrollHandler);
    },

    removeScrollListener() {
      this.$refs.content.removeEventListener('scroll', this.scrollHandler);
    },

    setScrollValues() {
      this.$nextTick(() => {
        this.fullContentSize = this.width
          ? this.$refs.content.scrollWidth
          : this.$refs.content.scrollHeight;
        this.overflowSize = this.width
          ? this.$refs.content.scrollWidth - this.$refs.wrapper.offsetWidth
          : this.$refs.content.scrollHeight - this.$refs.wrapper.offsetHeight;
      });
    },

    scrollHandler() {
      this.scrolled = this.width
        ? this.$refs.content.scrollLeft
        : this.$refs.content.scrollTop;
      this.currentScroll = this.scrolled / this.overflowSize;
    },
  },

  beforeDestroy() {
    this.removeScrollListener();
  },
};
