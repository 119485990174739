import {
  breadcrumbsIntegration,
  browserTracingIntegration,
  init,
  setContext,
  setUser,
} from '@sentry/browser';
import { debugIntegration } from '@sentry/integrations';
import { isNumber } from 'lodash-es';
import Vue from 'vue';

import {
  currentCompany,
  currentUser,
  sentryConfig,
} from 'lib/document-helpers/document-meta';

export default () => {
  const { dsn, environment, release } = sentryConfig();
  const integrations = [
    browserTracingIntegration(),
    breadcrumbsIntegration({
      dom: {
        serializeAttribute: ['data-testid', 'aria-label'],
      },
    }),
  ];
  if (process.env.NODE_ENV === 'development') {
    integrations.push(debugIntegration());
  }

  init({
    Vue,
    dsn,
    environment,
    dist: environment,
    release,
    integrations,

    // 1.0 captures 100% of performance traffic
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],

    beforeSend(event, hint) {
      const error = hint?.originalException;
      const isAxiosError = error?.isAxiosError;
      const isUnexpectedError =
        isNumber(error?.response?.status) && error.response.status >= 500;

      if (!isAxiosError || isUnexpectedError) {
        return event;
      }

      return null;
    },
  });

  const user = currentUser();
  const company = currentCompany();

  if (user && Object.prototype.hasOwnProperty.call(user, 'id')) {
    setUser(user);
  }

  if (company && Object.prototype.hasOwnProperty.call(company, 'id')) {
    setContext(company);
  }
};
