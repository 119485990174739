import flip from '@popperjs/core/lib/modifiers/flip';
import offset from '@popperjs/core/lib/modifiers/offset';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import { createPopper } from '@popperjs/core/lib/popper-lite';

import FadeTransition from 'app/brand/transitions/FadeTransition.vue';

import fullWidthModifier from './modifiers/full-width-modifier';
import maxWidthModifier from './modifiers/max-width-modifier';

export const PopperProps = {
  placements: [
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ],

  strategies: ['absolute', 'fixed'],
};

export default {
  name: 'Popper',

  components: {
    FadeTransition,
  },

  props: {
    /** The arrow styles which determines whether to render the unstyled popper arrow that tails the content overlay. */
    arrowClass: {
      type: String,
      default: undefined,
    },
    /** The offset placement of the arrow that can be used to ensure it's slightly overlapping the main overlay container */
    arrowOffset: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    tagName: {
      type: String,
      default: 'span',
    },
    placement: {
      type: String,
      default: 'bottom-start',
      validator: (val) => PopperProps.placements.includes(val),
    },
    strategy: {
      type: String,
      default: 'absolute',
      validator: (val) => PopperProps.strategies.includes(val),
    },
    portalTarget: {
      type: String,
      default: undefined,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: undefined,
    },
    offsetSkidding: {
      type: Number,
      default: undefined,
    },
    offsetDistance: {
      type: Number,
      default: undefined,
    },
    /** The popper container styles. */
    popperClass: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      popper: null,
    };
  },

  computed: {
    referenceElement() {
      if (this.$slots.default?.length) {
        return this.$slots.default[0].elm;
      }
      return null;
    },
  },

  mounted() {
    if (!this.popper) {
      this.$nextTick(() => this.createPopper());
    }
  },

  beforeDestroy() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  },

  watch: {
    show() {
      this.$nextTick(() => this.$nextTick(() => this.popper?.update()));
    },
    fullWidth() {
      this.updateOptions();
    },
    maxWidth() {
      this.updateOptions();
    },
    placement() {
      this.updateOptions();
    },
    strategy() {
      this.updateOptions();
    },
    offsetSkidding() {
      this.updateOptions();
    },
    offsetDistance() {
      this.updateOptions();
    },
  },

  methods: {
    createPopper() {
      this.popper = createPopper(
        this.referenceElement,
        this.$refs.popper,
        this.getPopperOptions()
      );
    },

    updateOptions() {
      if (this.popper) {
        this.popper.setOptions(this.getPopperOptions());
      }
    },

    getPopperOptions() {
      let { placement } = this;
      const { fullWidth, strategy, offsetSkidding, offsetDistance, maxWidth } =
        this;
      const modifiers = [
        flip,
        preventOverflow,
        {
          name: 'eventListeners',
          enabled: !!this.show,
        },
      ];

      if (
        typeof offsetSkidding === 'number' ||
        typeof offsetDistance === 'number'
      ) {
        modifiers.push({
          ...offset,
          options: {
            offset: [offsetSkidding, offsetDistance],
          },
        });
      }

      if (typeof maxWidth === 'number') {
        modifiers.push({
          ...maxWidthModifier,
          options: { maxWidth, fullWidth },
        });
      } else if (fullWidth) {
        modifiers.push(fullWidthModifier);

        if (['top', 'top-end'].includes(placement)) {
          placement = 'top-start';
        } else if (['bottom', 'bottom-end'].includes(placement)) {
          placement = 'bottom-start';
        }
      }

      return {
        placement,
        strategy,
        modifiers,
      };
    },
  },
};
