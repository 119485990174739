import SingleOtpInput from './single-otp-input/SingleOtpInput.vue';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
  name: 'OneTimePassword',

  components: {
    SingleOtpInput,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
    inputsNumber: {
      type: Number,
      default: 6,
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeInput: 0,
      otp: [],
      oldOtp: [],
    };
  },

  methods: {
    handleFocus(index) {
      this.activeInput = index;
    },

    handleBlur(event) {
      this.activeInput = -1;

      // Only call Formulate blur if blur is outside of the OTP inputs
      if (!event.relatedTarget?.id.includes('otp')) {
        this.context.blurHandler();
      }
    },

    // Helper to return OTP from input
    checkFilledAllInputs() {
      if (this.otp.join('').length === this.inputsNumber) {
        this.context.model = this.otp.join('');
        return this.$emit('complete', this.otp.join(''));
      }
      return 'Wait until the user enters the required number of characters';
    },

    // Focus on input by index
    focusInput(input) {
      this.activeInput = Math.max(Math.min(this.inputsNumber - 1, input), 0);
    },

    // Focus on next input
    focusNextInput() {
      this.focusInput(this.activeInput + 1);
    },

    // Focus on previous input
    focusPrevInput() {
      this.focusInput(this.activeInput - 1);
    },

    // Change OTP value at focused input
    changeCodeAtFocus(value) {
      this.oldOtp = Object.assign([], this.otp);
      this.$set(this.otp, this.activeInput, value);
      if (this.oldOtp.join('') !== this.otp.join('')) {
        this.context.model = this.otp.join('');
        this.$emit('on-change', this.otp.join(''));
        this.checkFilledAllInputs();
      }
    },

    // Handle pasted OTP
    handlePaste(event) {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData('text/plain')
        .slice(0, this.inputsNumber - this.activeInput)
        .split('');
      if (this.inputType === 'number' && !pastedData.join('').match(/^\d+$/)) {
        return 'Invalid pasted data';
      }
      // Paste data from focused input onwards
      const currentCharsInOtp = this.otp.slice(0, this.activeInput);
      const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
      this.$set(
        this,
        'otp',
        combinedWithPastedData.slice(0, this.inputsNumber)
      );
      this.focusInput(
        combinedWithPastedData.slice(0, this.inputsNumber).length
      );
      return this.checkFilledAllInputs();
    },

    handleChange(value) {
      this.changeCodeAtFocus(value);
      this.focusNextInput();
    },

    clearInput() {
      if (this.otp.length > 0) {
        this.context.model = '';
        this.$emit('on-change', '');
      }
      this.otp = [];
      this.activeInput = 0;
    },

    // Handle cases of backspace, delete, left arrow, right arrow
    handleKeyDown(event) {
      switch (event.keyCode) {
        case BACKSPACE:
          event.preventDefault();
          this.changeCodeAtFocus('');
          this.focusPrevInput();
          break;
        case DELETE:
          event.preventDefault();
          this.changeCodeAtFocus('');
          break;
        case LEFT_ARROW:
          event.preventDefault();
          this.focusPrevInput();
          break;
        case RIGHT_ARROW:
          event.preventDefault();
          this.focusNextInput();
          break;
        default:
          break;
      }
    },
  },
};
