<template>
  <FormulateForm
    v-slot="{ isLoading }"
    :values="channels"
    :form-errors="formErrors"
    :errors="inputErrors"
    @submit="submitHandler"
  >
    <FormulateInput
      type="group"
      name="deliveryChannels"
      :repeatable="true"
      remove-label="×"
      validation-name="Sharepoint channel"
      validation="min:1,length"
      add-label="+ Add another Sharepoint Channel"
      error-behavior="submit"
    >
      <FormulateInput
        name="channelName"
        validation="required"
        validation-name="Channel Name"
        placeholder="Name your Sharepoint Channel"
      />
    </FormulateInput>
    <FormulateErrors class="text-red-400" />
    <Button type="submit" :is-loading="isLoading || isSaving">Save</Button>
  </FormulateForm>
</template>

<script>
import axios from 'axios';

import Button from 'app/core/button/Button.vue';
import { sharepointPath } from 'routes';

export default {
  name: 'ManageSharepointForm',

  components: {
    Button,
  },

  props: {
    sharepoint: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      formErrors: [],
      inputErrors: {},
    };
  },

  computed: {
    channels() {
      return {
        deliveryChannels: this.sharepoint.channels.map((x) => {
          return { channelName: x };
        }),
      };
    },
  },

  methods: {
    async submitHandler(data) {
      const payload = {
        sharepoint: {
          channels: data.deliveryChannels.map((x) => x.channelName.trim()),
        },
      };
      if (
        new Set(payload.sharepoint.channels).size !==
        payload.sharepoint.channels.length
      ) {
        this.formErrors = ['All channel names must be unique'];
        return;
      }
      this.formErrors = [];

      this.isSaving = true;
      try {
        const resp = await axios.patch(
          sharepointPath(this.sharepoint.id),
          payload
        );
        if (resp?.status === 200) {
          this.$toast('Your Sharepoint delivery channels were saved', {
            type: 'success',
          });
        }
      } catch (err) {
        this.$formulate.handle(err, 'sharepoint');
        this.$toast('Unable to save channels', { type: 'error' });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
