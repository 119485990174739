/**
 * Converts an an array of strings into a comma separated list sutable for use in a sentence.
 *
 * toSentence(['a']) => 'a'
 * toSentence(['a', 'b']) => 'a and b'
 * toSentence(['a', 'b', 'c']) => 'a, b, and c'
 * toSentence(['a', 'b', 'c'], 'or') => 'a, b, or c'
 */
export function toSentence(arr, operator = 'and') {
  if (arr.length === 2) {
    return arr.join(` ${operator} `);
  }
  if (arr.length > 2) {
    const firstSeveral = `${arr.slice(0, arr.length - 1).join(', ')}`;
    const last = arr.slice(-1);
    return `${firstSeveral}, ${operator} ${last}`;
  }

  return arr.join();
}
