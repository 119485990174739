var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    ("formulate-input-element formulate-input-element--" + (_vm.context.type) + " search-wrapper search-wrapper--" + _vm.variant),
    {
      'search-wrapper--multiselect-editing': _vm.editing,
      'search-wrapper--has-focus': _vm.hasFocus,
    } ],attrs:{"data-type":_vm.context.type}},[(!_vm.editing)?_c('SearchIcon',{staticClass:"search-wrapper__icon"}):_vm._e(),_vm._v(" "),_c('FadeTransition',{attrs:{"enter-duration":"duration-300","leave-duration":"duration-[0s]","enter-delay":"delay-500","enter-timing":"ease-in-out","leave-timing":"ease-in-out"}},[(_vm.isLoading)?_c('LoadingIcon',{staticClass:"search-wrapper__loading"}):_vm._e()],1),_vm._v(" "),_c('AutocompleteInput',{attrs:{"behavior":_vm.behavior,"context":_vm.context,"infinite-status":_vm.infiniteStatus,"options":_vm.options,"editing":_vm.editing,"variant":_vm.variant},on:{"selected":_vm.handleSelected,"input-change":_vm.handleInputChange,"focus":function($event){_vm.hasFocus = true},"blur":function($event){_vm.hasFocus = false},"scroll-info":_vm.handleScrollInfo}}),_vm._v(" "),(_vm.model.length > 0 || _vm.defaultExpand)?_c('BadgeGroup',{class:[
      'min-h-[80px]',
      _vm.editing ? '' : 'border-gray-250 p-4',
      {
        'border-t-2': !_vm.editing && _vm.variant === 'primary',
        'border-t': !_vm.editing && _vm.variant === 'secondary',
      } ]},_vm._l((_vm.model),function(item){return _c('Badge',{key:item.id,attrs:{"interactive":_vm.isFunction(_vm.interactiveItems)
          ? _vm.interactiveItems(item)
          : _vm.interactiveItems,"color":_vm.getBadgeColorFunction(item),"show-remove-button":true,"show-warn-icon":_vm.showWarnIcon(item),"source":_vm.getOmnisearchSource(item),"locked":_vm.syncLocked &&
        ['synced', 'automatic'].includes(_vm.getOmnisearchSource(item)),"disabled":_vm.context.attributes.disabled,"model":item.model,"icon":_vm.getBadgeIconFunction(item),"icon-size-class":_vm.getBadgeIconSizeFunction(item)},on:{"click":function($event){return _vm.handleClick(item)},"remove":function($event){return _vm.handleRemove(item)}}},[_vm._v("\n      "+_vm._s(_vm.getOmnisearchLabel(item))+"\n    ")])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }