<template>
  <ResizeObserver v-slot="{ width }">
    <ScrollContainer
      :width="width"
      :gradient-color="gradientColor"
      container-classes="pb-4"
    >
      <div
        class="inline-block min-w-full align-middle"
        :class="{ 'py-2': shadow }"
      >
        <table
          class="ws-table"
          :class="[{ shadow: shadow }, `ws-table--${variant}`]"
        >
          <slot name="head" />

          <tbody>
            <slot />
          </tbody>
        </table>
      </div>
    </ScrollContainer>
  </ResizeObserver>
</template>

<script>
import ScrollContainer, {
  GradientColors,
} from 'app/core/scroll-container/ScrollContainer.vue';
import ResizeObserver from 'app/utilities/resize-observer/ResizeObserver.vue';

export const TableProps = {
  variants: ['primary', 'secondary', 'tertiary'],
};

export default {
  name: 'Table',

  components: {
    ResizeObserver,
    ScrollContainer,
  },

  props: {
    gradientColor: {
      type: String,
      required: false,
      default: 'gray',
      validator: (val) => GradientColors.includes(val),
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    /**
     * Affects table size and styling
     *
     * primary: used for top level tables on index pages
     * secondary: used for tables within cards
     * tertiary: used for mini-tables within cards, typically accompanying analytics charts
     */
    variant: {
      type: String,
      validator: (val) => TableProps.variants.includes(val),
      default: 'primary',
    },
  },
};
</script>
