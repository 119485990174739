<template>
  <div class="flex items-center py-2">
    <div
      :class="{
        avatar0: !option.avatarUrl,
      }"
      class="avatar mr-2.5 flex size-6 shrink-0 grow-0 items-center justify-center rounded-full border border-transparent font-bold group-hover:border-gray-200 group-hover:bg-white"
    >
      <img
        v-if="option.avatarUrl"
        class="w-full rounded-[50%] group-hover:opacity-50"
        :src="option.avatarUrl"
        alt="Workvivo avatar"
        aria-role="presentation"
      />
    </div>
    <div class="min-w-0 truncate pr-4 font-medium">
      {{ option.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkvivoAutocompleteOption',

  props: {
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>
