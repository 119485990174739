<template>
  <FormulateForm
    v-slot="{ isLoading }"
    :values="channels"
    :form-errors="formErrors"
    :errors="inputErrors"
    @submit="submitHandler"
  >
    <FormulateInput
      type="group"
      name="deliveryChannels"
      :repeatable="true"
      remove-label="×"
      validation-name="Teams channel"
      add-label="+ Add another Teams Channel"
      error-behavior="submit"
    >
      <div class="flex">
        <FormulateInput
          class="mr-2 w-1/3"
          name="channelName"
          validation="required"
          validation-name="Channel Name"
          placeholder="Teams Channel Name"
        />
        <FormulateInput
          class="w-2/3"
          name="webhook"
          validation="required"
          validation-name="Webhook"
          placeholder="Your channel's unique webhook"
        />
      </div>
    </FormulateInput>
    <FormulateErrors class="text-red-400" />
    <Button type="submit" :is-loading="isLoading || isSaving">Save</Button>
  </FormulateForm>
</template>

<script>
import axios from 'axios';

import Button from 'app/core/button/Button.vue';
import { teamChannelsPath } from 'routes';

export default {
  name: 'ManageTeamsForm',

  components: {
    Button,
  },

  props: {
    teams: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      formErrors: [],
      inputErrors: {},
    };
  },

  computed: {
    channels() {
      return {
        deliveryChannels: this.teams.channels,
      };
    },
  },

  methods: {
    async submitHandler(data) {
      const payload = {
        channels: data.deliveryChannels,
      };
      if (new Set(payload.channels).size !== payload.channels.length) {
        this.formErrors = ['All channel names must be unique'];
        return;
      }
      this.formErrors = [];

      this.isSaving = true;
      try {
        const resp = await axios.post(
          teamChannelsPath({ team_id: this.teams.id }),
          payload
        );
        if (resp?.status === 200) {
          this.$toast('Your Teams channels were saved', {
            type: 'success',
          });
        }
      } catch (err) {
        this.$formulate.handle(err, 'teams');
        this.$toast('Unable to save channels', { type: 'error' });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
