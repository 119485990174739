var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ConfigureWorkvivoRadio',{attrs:{"disabled":_vm.disabled,"is-audience-everyone":_vm.isAudienceEveryone,"options":_vm.radioOptions}}),_vm._v(" "),(_vm.isAudienceEveryone)?_c('FormulateInput',{key:"everyone",staticClass:"mb-0",attrs:{"disabled":_vm.disabled,"type":"group","help":_vm.isAudienceEveryone &&
      "If you select “Everyone”, this post will appear on the Activity Feed for every person in the organization.","help-icon":""}}):(_vm.isAudienceSpaces)?_c('FormulateInput',{key:"spaces",attrs:{"disabled":_vm.disabled,"type":"multiselect","name":"spaces","default-expand":true,"placeholder":"Search spaces...","search-endpoint":_vm.searchEndpoint,"search-params":{ 'q[channelTypeEq]': _vm.CHANNEL_TYPE.SPACE },"search-query":"channelNameCont","variant":"primary","validation":"required"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
      var option = ref.option;
return [_c('WorkvivoAutocompleteOption',{attrs:{"option":option}})]}}])}):(_vm.isAudienceTeams)?_c('FormulateInput',{key:"teams",attrs:{"disabled":_vm.disabled,"type":"multiselect","name":"teams","default-expand":true,"placeholder":"Search teams...","search-endpoint":_vm.searchEndpoint,"search-params":{ 'q[channelTypeEq]': _vm.CHANNEL_TYPE.TEAM },"search-query":"channelNameCont","variant":"primary","validation":"required"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
      var option = ref.option;
return [_c('WorkvivoAutocompleteOption',{attrs:{"option":option}})]}}])}):_vm._e(),_vm._v(" "),_c('FormulateInput',{attrs:{"name":"title","variant":"secondary","disabled":_vm.disabled,"validation":!_vm.disabled && 'required',"validation-name":"The Workvivo post title","label":"Title","outer-class":[
      'mt-6',
      _vm.disabled ? 'editing' : '',
      'formulate-input--secondary' ],"value":_vm.deliveryDetailsFormData.title}}),_vm._v(" "),_c('FormulateInput',{attrs:{"disabled":_vm.disabled,"name":"subtitle","variant":"secondary","label":"Subtitle","outer-class":[_vm.disabled ? 'editing' : '', 'formulate-input--secondary'],"value":_vm.deliveryDetailsFormData.subtitle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }