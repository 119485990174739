var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'avatar',
    _vm.prefixClass(_vm.size),
    _vm.getBgColorClass(),
    _vm.getSmileyClass(_vm.size),
    {
      'avatar--discarded': _vm.discarded || _vm.person.discardedAt,
      'avatar--group': _vm.person.model === 'userGroup',
      'avatar--has-img': _vm.person.avatarTag,
      'avatar--hovered': _vm.hovered,
      smiley: _vm.person.role && !_vm.person.avatarTag,
    } ]},[(_vm.placeholder || (_vm.person.gdprCompliant && _vm.person.discardedAt))?_c('UserIcon',{staticClass:"avatar__placeholder",attrs:{"data-testid":"placeholder-icon"}}):(_vm.person.avatarTag)?_c('div',{staticClass:"avatar__img",domProps:{"innerHTML":_vm._s(_vm.person.avatarTag)}}):(_vm.person.role)?_c(_vm.getSmiley(),{tag:"component",staticClass:"smiley__face"}):[(_vm.hasFullName)?_c('span',{staticClass:"avatar__initial"},[_vm._v("\n      "+_vm._s(_vm.getInitial(_vm.person))+"\n    ")]):[(_vm.person.smsOnly)?_c('TextIcon',{staticClass:"avatar__text-icon"}):_c('EmailIcon',{staticClass:"avatar__email-icon"})]],_vm._v(" "),(_vm.discarded || _vm.person.discardedAt)?_c('XIcon',{staticClass:"avatar__x-icon",attrs:{"data-testid":"discarded-icon"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }