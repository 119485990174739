<template>
  <Button
    data-testid="removeButton"
    variant="tertiary"
    size="xs"
    :circle="true"
    class="!pr-6"
    :disabled="disabled"
    aria-label="Remove"
    @click="handleClick"
  >
    <DeleteIcon
      class="size-1.5"
      :class="({ 'opacity-50': disabled }, iconColorClass)"
    />
  </Button>
</template>

<script>
import DeleteIcon from '@workshop/baja/assets/icons/icon-x.svg';
import Button from 'app/core/button/Button.vue';

export default {
  name: 'BadgeRemoveButton',

  components: {
    Button,
    DeleteIcon,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    iconColorClass: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>
