<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <div v-if="okta.status === 'pending_activation' || showSetup">
      <Title :level="3">Set Up Okta Syncing</Title>
      <p class="mt-2">
        To begin, please
        <Link
          href="https://developer.okta.com/docs/guides/create-an-api-token/main/"
          :external="true"
          >create an Okta API Token</Link
        >
        and paste it below. Workshop only requires read-only access to Okta, and
        we recommend create a read only service account.
      </p>

      <FormulateForm
        v-slot="{ isLoading }"
        :form-errors="formErrors"
        name="authorizeOkta"
        class="mt-2 w-full"
        @submit="authorizeOkta"
      >
        <FormulateInput
          type="text"
          name="apiKey"
          label="Okta API Token"
          validation="required"
          class="w-full"
        />

        <FormulateInput
          type="text"
          name="organizationName"
          label="Okta Organization Name"
          placeholder="company-name"
          validation="required"
          class="w-full"
        />
        <p class="mb-2 text-sm italic">
          Your organization name can be found in your Okta URL, for example
          https://company-name.okta.com.
        </p>
        <Button type="submit" :is-loading="isLoading" class="mt-2">
          Connect to Okta
        </Button>
      </FormulateForm>
    </div>

    <div v-if="okta.status === 'active' && !showSetup">
      <Title :level="3">Okta Syncing</Title>
      <ContentPlaceholder class="my-10">
        <template #title>
          <div class="flex flex-col items-center">
            <CheckmarkIcon class="mb-4 w-5 text-blue-500" />
            Okta is connected!
            <p class="mt-2 font-normal text-gray-500">
              Last Synced {{ lastSyncedAgo }}
            </p>

            <Button
              class="mt-4 w-full md:w-auto"
              data-testid="generate-api-key-button"
              variant="secondary"
              @click="displaySetupInputs"
            >
              Use a different API Key
            </Button>
          </div>
        </template>
      </ContentPlaceholder>
    </div>
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import axios from 'axios';
import * as timeago from 'timeago.js';
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Button from 'app/core/button/Button.vue';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';
import Title from 'app/core/title/Title.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';
import { oktaPath } from 'routes';

export default {
  name: 'ManageOkta',

  components: {
    AppPlaceholder,
    Button,
    ContentPlaceholder,
    LoadingIcon,
    Title,
  },

  data() {
    return {
      formErrors: [],
      showSetup: false,
    };
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      okta: 'connectedData',
    }),
    lastSyncedAgo() {
      return timeago.format(this.okta.lastSyncedAt);
    },
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },

    displaySetupInputs() {
      this.showSetup = true;
    },

    async authorizeOkta(data) {
      try {
        await axios.patch(oktaPath(this.okta.id), {
          okta: data,
        });
        this.getConnected({ type: this.app.type });
      } catch {
        this.formErrors = [
          'Could not connect to Okta with provided credentials.',
        ];
      }
    },
  },
};
</script>
