<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive" class="w-full">
    <ManageTeamsForm :teams="teams" />
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';

import ManageTeamsForm from './ManageTeamsForm.vue';

export default {
  name: 'ManageTeams',

  components: {
    AppPlaceholder,
    LoadingIcon,
    ManageTeamsForm,
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      teams: 'connectedData',
    }),
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },
  },
};
</script>
