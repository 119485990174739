import Vue from 'vue';
import Toast from 'vue-toastification';

// map Flash types to vue-toastification types
const flashTypeMap = new Map([
  ['info', 'info'],
  ['notice', 'success'],
  ['warn', 'warning'],
  ['alert', 'error'],
]);

/**
 * Display notifications serialized from ActionDispatch::Flash at page load
 */
function showPageNotifications(notifications) {
  notifications.forEach((notification) => {
    if (notification.msg && flashTypeMap.has(notification.type)) {
      Vue.$toast(notification.msg, {
        type: flashTypeMap.get(notification.type),
      });
    } else {
      throw new Error(
        `Unexpected flash notification format: ${JSON.stringify(notification)}`
      );
    }
  });
}

/**
 * Get the current toast container
 */
function getToastContainer() {
  return document.querySelector('.toast-container');
}

/**
 * Prevents toasts of the same type from appearing simultaneously, discarding duplicates
 */
function filterBeforeCreate(toast, toasts) {
  const duplicateToasts = toasts.filter(
    (t) => t.type === toast.type && t.content === toast.content
  );
  if (duplicateToasts.length !== 0) {
    return false;
  }
  return toast;
}

export default function initializeToasts() {
  // disable toasts on print pages
  if (document.documentElement.classList.contains('print')) {
    return;
  }

  const toastContainer = getToastContainer();

  const toastOptions = {
    position: 'bottom-right',
    maxToasts: 10,
    transition: 'Vue-Toastification__custom-fade',
    transitionDuration: 330,
    closeOnClick: false,
    timeout: 8000,
    hideProgressBar: true,
    container: getToastContainer,
    filterBeforeCreate,
  };

  // call the plugin init method directly rather than Vue.use in order to force re-initialization
  // on turbolinks load - allows mounting a new container element
  Toast(Vue, toastOptions);

  // check for notifications from the server, but let Vue 3 toast system take precedence for this if it's loaded
  const hasVue3Page = document.querySelectorAll(`[data-vue-page]`).length;
  const hasNotifications = !!toastContainer?.dataset.flash?.length;

  if (hasVue3Page || !hasNotifications) {
    return;
  }

  const notifications = JSON.parse(toastContainer.dataset.flash);

  if (Array.isArray(notifications)) {
    window.addEventListener('load', () => showPageNotifications(notifications));
  }
}
