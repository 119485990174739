import Avatar from 'app/core/avatar/Avatar.vue';
import Tooltip from 'app/core/tooltip/Tooltip.vue';

import NameTagAvatar from './name-tag-avatar/NameTagAvatar.vue';

export const NameTagProps = {
  sizes: ['sm', 'md'],
};

export default {
  name: 'NameTag',

  components: {
    Avatar,
    NameTagAvatar,
    Tooltip,
  },

  props: {
    hideLineTwo: {
      type: Boolean,
      default: false,
    },

    person: {
      type: Object,
      required: true,
    },

    size: {
      type: String,
      default: 'md',
      validator: (val) => NameTagProps.sizes.includes(val),
    },

    lineTwo: {
      type: String,
      default: '',
    },

    hovered: {
      type: Boolean,
      default: undefined,
    },
  },
};
