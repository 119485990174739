/* eslint-disable no-param-reassign */
<template>
  <div class="flex w-full flex-col justify-start p-4 leading-4 shadow-card">
    <p class="mb-2">{{ customMessage }}</p>
    <p class="mb-2">
      <a href="#" class="pointer-events-none">{{ header }}</a>
    </p>
    <p class="mb-2">{{ messageBody }}</p>
  </div>
</template>

<script>
export default {
  name: 'TeamsCard',

  props: {
    customMessage: {
      type: String,
      required: true,
    },
    email: {
      type: Object,
      required: true,
    },
    messageBody: {
      type: String,
      required: true,
    },
  },

  computed: {
    header() {
      return this.email.subjectLine || '(No Subject)';
    },
  },
};
</script>

<style scoped>
div {
  font-family: 'Segoe UI', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
p {
  width: 100%;
}
a {
  color: #6264a7;
}
</style>
