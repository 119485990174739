import { debounce, isFunction, isString } from 'lodash-es';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Popper from 'app/core/popper/Popper.vue';
import scrollMixin from 'mixins/scroll-mixin';

import AutocompleteOption from './autocomplete-option/AutocompleteOption.vue';

export const AutocompleteInputProps = {
  behaviors: ['input', 'tag', 'select', 'tagExpand'],
  variants: ['primary', 'secondary'],
};

export default {
  name: 'AutocompleteInput',

  components: {
    AutocompleteOption,
    LoadingIcon,
    Popper,
  },

  props: {
    editing: {
      type: Boolean,
      required: false,
    },
    context: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    behavior: {
      type: String,
      default: 'text',
      validator: (val) => AutocompleteInputProps.behaviors.includes(val),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (val) => AutocompleteInputProps.variants.includes(val),
    },
    portalTarget: {
      type: String,
      default: undefined,
    },
    infiniteStatus: {
      type: [String, Boolean],
      default: undefined,
    },
    inputClass: {
      type: String,
      default: undefined,
    },
    wrapperClass: {
      type: String,
      default: undefined,
    },
  },

  mixins: [
    scrollMixin({
      handleScrollInfoMethodName: 'handleScrollInfo',
      infinitePlacerRefName: 'infinitePlacer',
      scrollerRefName: 'autocompleteList',
    }),
  ],

  data() {
    return {
      selectedIndex: -1,
      query: '',
      dropdownActive: false,
      debounceInputChange: debounce(this.handleInputChange, 300),
    };
  },

  computed: {
    model() {
      return this.context.model;
    },

    disableSynced() {
      return this.context.slotProps.component.disableSynced;
    },

    selection() {
      if (this.options[this.selectedIndex]) {
        return this.options[this.selectedIndex];
      }
      return undefined;
    },

    showDropdown() {
      return !!(this.dropdownActive && this.options && this.options.length);
    },

    /**
     * Map context.model to a string for display in the text input
     *
     * Default behavior is to extract the name or title field
     */
    mapLabelFunction() {
      const fn = this.context.slotProps.component.mapLabelFunction;
      return isFunction(fn)
        ? fn
        : (option) => {
            if (isString(option)) {
              return option;
            }
            if (option?.model === 'list') {
              return `${option.title} (${option.listMembershipsCount})`;
            }

            if (option?.model === 'user') {
              if (option?.firstName !== '_' && option?.lastName !== '_') {
                return option.name;
              }

              return option.smsOnly ? option.phoneNumber : option.email;
            }

            return (
              option?.name ||
              option?.title ||
              option?.email ||
              option?.phoneNumber ||
              ''
            );
          };
    },

    /**
     * Map an option to a value for context.model
     *
     * Default behavior is to pass through the value directly
     */
    mapValueFunction() {
      const fn = this.context.slotProps.component.mapValueFunction;
      return isFunction(fn) ? fn : (option) => option;
    },

    /**
     * Custom logic to determine if an option is disabled
     */
    isDisabledFunction() {
      const fn = this.context.slotProps.component.isDisabledFunction;
      return isFunction(fn) ? fn : () => false;
    },
  },

  watch: {
    options(value) {
      if (value.length > 0) {
        this.openDropdown();
      }

      this.selectedIndex = value.findIndex(
        (option) =>
          this.query?.toLowerCase() ===
          this.mapLabelFunction(this.mapValueFunction(option)).toLowerCase()
      );
    },

    model: {
      immediate: true,
      handler(value) {
        if (this.behavior === 'select' || this.behavior === 'input') {
          this.query = this.mapLabelFunction(value);
        } else {
          this.query = '';
        }

        // only close if valid model value due to bug where dropdown was prematurely closing prior to the click event
        if (value?.length) {
          this.closeDropdown();
        }
      },
    },
  },

  beforeDestroy() {
    this.closeDropdown();
  },

  methods: {
    isOptionSelected(option) {
      if (!this.selection) {
        return false;
      }

      if (isString(option)) {
        return this.selection === option;
      }

      return this.selection.id === option.id;
    },

    isOptionDisabled(option) {
      if (this.isDisabledFunction(option)) {
        return true;
      }

      if (this.disableSynced) {
        return (
          option.synced ||
          (option.model === 'list' &&
            ['automatic', 'synced'].includes(option.listSource))
        );
      }
      return false;
    },

    handleInputFocus(event) {
      if (this.behavior === 'select') {
        event.target.select();
      }
      if (
        // tagExpand is used to auto fetch suggestions for the multiselect in automatic list matchers
        ['select', 'input', 'tagExpand'].includes(this.behavior) &&
        (!this.model || (Array.isArray(this.model) && this.model.length === 0))
      ) {
        this.$emit('input-change', this.query);
      }

      this.$emit('focus', event);
    },

    handleInputClick() {
      // trigger auto fetch on click
      if (this.behavior === 'tagExpand') {
        this.$emit('input-change', this.query);
      }
    },

    handleInputBlur(event) {
      const newFocus = event.relatedTarget;
      const listEl = this.$refs.autocompleteList;

      // don't trigger validation if focus is moving to the list
      if (!newFocus || !listEl || !listEl.contains(newFocus)) {
        this.context.blurHandler(event);
        this.$emit('blur', event);
        this.closeDropdown();
      }
    },

    handleInputChange(event) {
      // only respond to user input (not changes caused by model binding)
      if (event?.type === 'input') {
        this.$emit('input-change', this.query);
      }
    },

    handleOptionClick(option) {
      if (this.isOptionDisabled(option)) {
        return;
      }

      if (this.behavior !== 'input') {
        this.query = '';
      }

      if (option || this.behavior === 'select') {
        this.$emit('selected', this.mapValueFunction(option), option);
      }

      if (this.behavior === 'select') {
        this.$refs.autocompleteInput.blur();
      } else {
        this.$refs.autocompleteInput.focus();
      }
    },

    handleEnterPress() {
      if (this.selection) {
        this.handleOptionClick(this.selection);
      } else {
        this.closeDropdown();
      }
    },

    handleScrollInfo(scrollInfo) {
      if (scrollInfo) {
        this.$emit('scroll-info', scrollInfo);
      }
    },

    increment() {
      const { length } = this.options;
      this.selectedIndex = length
        ? Math.min(this.selectedIndex + 1, length - 1)
        : -1;
    },

    decrement() {
      const { length } = this.options;

      if (!length) {
        this.selectedIndex = -1;
      } else if (this.selectedIndex === -1) {
        this.selectedIndex = length - 1;
      } else {
        this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
      }
    },

    openDropdown() {
      if (!this.dropdownActive) {
        document.addEventListener('click', this.clickListener);
        this.dropdownActive = true;
      }
    },

    closeDropdown() {
      if (this.dropdownActive) {
        this.dropdownActive = false;
        document.removeEventListener('click', this.clickListener);
      }
    },

    clickListener(event) {
      const clickInsideDropdown = this.$refs.autocomplete.contains(
        event.target
      );
      if (!clickInsideDropdown) {
        this.hideDropdown();
      }
    },

    hideDropdown() {
      // clear the selection if there is invalid input
      if (
        this.behavior === 'select' &&
        this.model &&
        this.query !== this.mapLabelFunction(this.model)
      ) {
        this.query = '';
        this.$emit('selected');
      }

      this.closeDropdown();
    },
  },
};
