<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <div v-if="azure.status === 'pending_activation'">
      <Title :level="3">Set up Azure AD Syncing</Title>
      <p>
        To begin, please authorize an Azure AD administrator account. Workshop
        only uses read only scopes to access and sync your ActiveDirectory's
        users and groups.
      </p>
      <Button
        class="mt-9 w-full md:w-auto"
        data-testid="generate-api-key-button"
        @click="initiateOAuth"
      >
        Authorize ActiveDirectory
      </Button>
    </div>

    <div v-if="azure.status === 'active'">
      <Title :level="3">Azure ActiveDirectory Syncing</Title>

      <ContentPlaceholder class="my-10">
        <template #title>
          <div class="flex flex-col items-center">
            <!-- When app is initially connected but first sync hasn't completed yet -->
            <template v-if="typeof azure.lastSyncSucceeded !== 'boolean'">
              <CheckmarkIcon class="mb-4 w-5 text-green-500" />
              Azure AD Connected!
              <p class="mt-2 flex flex-col font-normal italic">
                <span>Performing intial sync right now.</span>
                <span> This may take awhile. </span>
              </p>
            </template>
            <template v-else-if="azure.lastSyncSucceeded">
              <CheckmarkIcon class="mb-4 w-5 text-green-500" />
              <p class="font-normal">
                Last synced {{ lastSyncedAgo }} with account
                {{ azure.adminEmail }}
              </p>
            </template>
            <template v-else>
              <XIcon class="mb-4 w-5 text-red-500" />
              <p class="font-normal">
                Last sync failed {{ lastSyncedAgo }} with account
                {{ azure.adminEmail }}
              </p>
            </template>

            <Button
              class="mt-4 w-full md:w-auto"
              data-testid="generate-api-key-button"
              variant="secondary"
              @click="initiateOAuth"
            >
              Link a different AD Admin
            </Button>
          </div>
        </template>
        <template #graphic>
          {{ /* empty slot */ }}
        </template>
      </ContentPlaceholder>
    </div>
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import * as timeago from 'timeago.js';
import { mapActions, mapGetters } from 'vuex';

import CheckmarkIcon from '@workshop/baja/assets/icons/icon-checkmark-full.svg';
import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import XIcon from '@workshop/baja/assets/icons/icon-x-full.svg';
import Button from 'app/core/button/Button.vue';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';
import Title from 'app/core/title/Title.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';
import { userMicrosoftGraphOmniauthAuthorizeUrl } from 'routes';

export default {
  name: 'ManageAzureAdApi',

  components: {
    AppPlaceholder,
    Button,
    CheckmarkIcon,
    ContentPlaceholder,
    LoadingIcon,
    Title,
    XIcon,
  },

  data() {
    return {
      loadingApiKey: false,
      apiKey: null,
    };
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      azure: 'connectedData',
    }),

    lastSyncedAgo() {
      return timeago.format(this.azure.lastSyncedAt);
    },
  },

  created() {
    this.getConnected({ type: this.app.type });

    if (this.successParam()) {
      this.$toast('Your ActiveDirectory has been successfully linked!', {
        type: 'success',
      });
    }
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },

    async initiateOAuth() {
      window.location = userMicrosoftGraphOmniauthAuthorizeUrl({
        azureIntegrationId: this.azure.id,
      });
    },

    successParam() {
      return !!new URLSearchParams(window.location.search).get('success');
    },
  },
};
</script>
