<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
    data-testid="loading-icon"
  />
  <div v-else-if="isActive" class="p-6" data-testid="sharepoint-manage-tab">
    <div class="rounded bg-gray-50 p-6">
      <Title :level="3">Your API Key</Title>
      <div class="mt-2 break-all text-lg">
        {{ sharepoint.fullApiKey }}
      </div>
      <p class="mt-2 text-sm italic">
        Copy and paste this into your Sharepoint Webpart
      </p>
    </div>
    <Title :level="3" class="mt-6">Your Sharepoint Channels</Title>
    <p class="mt-2 text-sm italic">
      Add Sharepoint Channels that will show up as Delivery Channels in Workshop
      and as Webparts in Sharepoint. Add the Workshop Webpart in your company
      Sharepoint to push updates.
    </p>
    <ManageSharepointForm class="mt-6" :sharepoint="sharepoint" />
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    data-testid="app-placeholder"
    @add-app="handleAddApp"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Title from 'app/core/title/Title.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';

import ManageSharepointForm from './ManageSharepointForm.vue';

export default {
  name: 'ManageSharepoint',

  components: {
    AppPlaceholder,
    LoadingIcon,
    ManageSharepointForm,
    Title,
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      sharepoint: 'connectedData',
    }),
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },
  },
};
</script>
