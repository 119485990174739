import { isDate, isString } from 'lodash-es';
import { DateTime } from 'luxon';

/**
 * Common functionality for all preview components
 */
export default {
  props: {
    update: {
      type: Object,
      required: true,
    },
    updateDate: {
      type: [Date, String],
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
    messageHeader: {
      type: String,
      default: undefined,
    },
    messageBody: {
      type: String,
      default: undefined,
    },
    appData: {
      type: Object,
      default: undefined,
    },
    featuredImage: {
      type: String,
      default: undefined,
    },
    /**
     * Object of selected courier's custom details.
     * e.g. Workvivo's custom title and subtitle values.
     */
    details: {
      type: Object,
      default: undefined,
    },
  },

  computed: {
    updateDateTime() {
      if (isDate(this.updateDate)) {
        return DateTime.fromJSDate(this.updateDate);
      }
      if (isString(this.updateDate)) {
        return DateTime.fromISO(this.updateDate);
      }
      return DateTime.now();
    },
  },
};
