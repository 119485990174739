import Avatar from 'app/core/avatar/Avatar.vue';
import Tooltip from 'app/core/tooltip/Tooltip.vue';

export const NameTagProps = {
  sizes: ['sm', 'md'],
};

export default {
  name: 'NameTagAvatar',

  components: {
    Avatar,
    Tooltip,
  },

  props: {
    hideLineTwo: {
      type: Boolean,
      default: false,
    },

    person: {
      type: Object,
      required: true,
    },

    size: {
      type: String,
      default: 'md',
      validator: (val) => NameTagProps.sizes.includes(val),
    },

    lineTwo: {
      type: String,
      default: '',
    },

    hovered: {
      type: Boolean,
      default: undefined,
    },
  },

  computed: {
    hasFullName() {
      return this.person.firstName !== '_' && this.person.lastName !== '_';
    },

    showLineTwo() {
      return this.hasFullName && !this.person.discardedAt && !this.hideLineTwo;
    },

    lineOne() {
      if (this.hasFullName) {
        return this.person.name;
      }
      return this.person.smsOnly ? this.person.phoneNumber : this.person.email;
    },
  },
};
