import { isArray, isError, isString } from 'lodash-es';

const NOT_FOUND = "We couldn't find that entity";
const UNKNOWN_ERROR = 'An unknown error ocurred';

/**
 * Parse a raw API error object into a serializable format which can be saved in a vuex store
 * and/or passed to Formulate using $formulate.handle()
 */
export const parseError = (err) => {
  if (err.response && err.response.status) {
    const { base, ...rest } = err.response.data;

    switch (err.response.status) {
      case 422:
        return { inputErrors: rest, formErrors: base };
      case 400:
        return { formErrors: [NOT_FOUND] };
      default:
        return { formErrors: [UNKNOWN_ERROR] };
    }
  } else {
    return { formErrors: [UNKNOWN_ERROR] };
  }
};

/**
 * Accepts an error message string, error object, or array of error messages and
 * unwraps any error objects to return just the error message string or an array of error message strings.
 */
export const getErrorMessages = (err) => {
  if (isArray(err)) {
    const mappedArray = err.map((e) => getErrorMessages(e));

    // use Set to deduplicate error messages
    return Array.from(new Set(mappedArray));
  }

  if (isString(err)) {
    return err;
  }

  return err.message || UNKNOWN_ERROR;
};

/**
 * Formulate error handler function
 *
 * This function is called implicitly by Formulate when you call $formulate.handle()
 *
 * Accepts either a raw error from a failed API call or a "parsed" error which was generated by the parseError function
 * Outputs errors in the format formulate expects for inline error display
 */
export default (err) => {
  const parsed = isError(err) ? parseError(err) : err;

  const { inputErrors, formErrors } = parsed;

  const inputErrorMessages = {};
  const formErrorMessages = {};

  if (inputErrors) {
    Object.keys(inputErrors).forEach((key) => {
      inputErrorMessages[key] = getErrorMessages(inputErrors[key]);
    });
  }

  if (formErrors) {
    Object.keys(formErrors).forEach((key) => {
      formErrorMessages[key] = getErrorMessages(formErrors[key]);
    });
  }

  return {
    inputErrors: inputErrorMessages,
    formErrors: formErrorMessages,
  };
};
