export default {
  name: 'FocusTrap',

  mounted() {
    const focusableList = this.getFocusableList();
    if (focusableList.length > 0) {
      focusableList[0].focus();
    }

    // if there is only 1 element, do not blur it because we'll lose
    // the next tab key press will take us out of the container and we'll
    // lose the ability to trap focus
    if (focusableList.length > 1) {
      focusableList[0].blur();
    }
  },

  methods: {
    checkKeyEvent(event) {
      const focusableList = this.getFocusableList();
      // escape early if only 1 or no elements to focus
      if (focusableList.length < 2 && event.key === 'Tab') {
        event.preventDefault();
        return;
      }
      const last = focusableList.length - 1;
      if (
        event.key === 'Tab' &&
        event.shiftKey === false &&
        event.target === focusableList[last]
      ) {
        event.preventDefault();
        focusableList[0].focus();
      } else if (
        event.key === 'Tab' &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault();
        focusableList[last].focus();
      }
    },

    getFocusableList() {
      return this.$el.querySelectorAll(
        'button, [href], input:not(.flatpickr-input), select, textarea, [tabindex]:not([tabindex="-1"])'
      );
    },
  },
};
