import { isUndefined } from 'lodash-es';

export const TitleProps = {
  levels: [1, 2, 3, 4, 5, 6],
  sizes: [1, 2, 3, 4, 5, 6, 7, 8],
  alignments: ['left', 'right', 'center'],
};

export default {
  props: {
    level: {
      type: Number,
      default: undefined,
      validator: (value) => TitleProps.levels.includes(value),
    },

    size: {
      type: Number,
      default: undefined,
      validator: (value) => TitleProps.sizes.includes(value),
    },

    align: {
      type: String,
      default: undefined,
      validator: (value) => TitleProps.alignments.includes(value),
    },

    tag: {
      type: String,
      default: undefined,
    },

    serif: {
      type: Boolean,
      default: undefined,
    },
  },

  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },

    serifClass() {
      const useSerif =
        this.serif || (isUndefined(this.serif) && this.level === 1);

      return useSerif ? 'font-serif !font-normal' : undefined;
    },

    tagOrDefault() {
      return this.tag || `h${this.level}` || 'div';
    },

    alignContainerClass() {
      switch (this.align) {
        case 'left':
          return 'justify-start';
        case 'right':
          return 'justify-end';
        case 'center':
          return 'justify-center';
        default:
          return null;
      }
    },

    alignHeaderClass() {
      switch (this.align) {
        case 'left':
          return 'text-left';
        case 'right':
          return 'text-right';
        case 'center':
          return 'text-center';
        default:
          return null;
      }
    },

    gapClass() {
      switch (this.size || this.level) {
        case 1:
          return 'gap-4';
        case 2:
          return 'gap-3';
        default:
          return 'gap-2.5';
      }
    },

    sizeClass() {
      switch (this.size || this.level) {
        case 1:
          return 'text-4xl font-serif';
        case 2:
          return 'text-3xl';
        case 3:
          return 'text-2xl';
        case 4:
          return 'text-xl';
        case 5:
          return 'text-lg';
        default:
        case 6:
          return 'text-base';
        case 7:
          return 'text-sm';
        case 8:
          return 'text-xs';
      }
    },
  },

  created() {
    if (isUndefined(this.level) && isUndefined(this.tag)) {
      throw new Error('Title component requires a "level" or "tag" prop');
    }

    if (
      !isUndefined(this.tag) &&
      isUndefined(this.level) &&
      isUndefined(this.size)
    ) {
      throw new Error(
        'Title component requires a "size" prop when using a custom tag'
      );
    }
  },
};
