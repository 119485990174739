import NameTag from 'app/core/name-tag/NameTag.vue';
import Title from 'app/core/title/Title.vue';
import PreviewMixin from 'mixins/preview-mixin';

import SharepointPlaceholderImage from './sharepoint-placeholder.svg';

export default {
  name: 'PreviewSharepoint',

  mixins: [PreviewMixin],

  components: {
    NameTag,
    SharepointPlaceholderImage,
    Title,
  },

  computed: {
    timestamp() {
      return this.updateDateTime.toFormat('DDD');
    },
  },
};
