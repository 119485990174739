<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <div v-if="google.status === 'pending_activation'">
      <Title :level="3">Set up Google Workspace Syncing</Title>
      <p class="my-4">
        <strong
          >To begin, please authorize a Google Workspace Administrator
          account.</strong
        >
        Workshop only uses read only scopes to access and sync your Google
        Workspace's users and groups.
      </p>
      <img
        :src="googleSigninImage"
        alt="Sign in with Google"
        class="w-48 hover:cursor-pointer"
        @click="initiateOAuth"
      />
    </div>

    <div v-if="google.status === 'active'">
      <Title :level="3">Google Workspace Syncing</Title>

      <ContentPlaceholder class="my-10">
        <template #title>
          <div class="flex flex-col items-center">
            <CheckmarkIcon class="mb-4 w-5 text-blue-500" />
            Google Connected!
            <p :title="google.lastSyncedAt"></p>
            <p class="mt-2 font-normal text-gray-500">
              Last Synced {{ lastSyncedAgo }} with account
              {{ google.adminEmail }}
            </p>

            <p class="mb-2 mt-4 text-gray-500">
              Connect a different Google Account:
            </p>
            <img
              :src="googleSigninImage"
              alt="Sign in with Google"
              class="w-full hover:cursor-pointer"
              @click="initiateOAuth"
            />
          </div>
        </template>
      </ContentPlaceholder>
    </div>
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import * as timeago from 'timeago.js';
import { mapActions, mapGetters } from 'vuex';

import CheckmarkIcon from '@workshop/baja/assets/icons/icon-checkmark.svg';
import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';
import Title from 'app/core/title/Title.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';
import GoogleSignin from 'assets/images/icons/apps/google-signin.png';
import { userGoogleAdminOmniauthAuthorizeUrl } from 'routes';

export default {
  name: 'ManageGoogle',

  components: {
    AppPlaceholder,
    CheckmarkIcon,
    ContentPlaceholder,
    LoadingIcon,
    Title,
  },

  data() {
    return {
      loadingApiKey: false,
      apiKey: null,
      googleSigninImage: GoogleSignin,
    };
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      google: 'connectedData',
    }),

    lastSyncedAgo() {
      return timeago.format(this.google.lastSyncedAt);
    },
  },

  created() {
    this.getConnected({ type: this.app.type });

    if (this.successParam()) {
      this.$toast('Your Google Directory has been successfully linked!', {
        type: 'success',
      });
    }
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },

    async initiateOAuth() {
      window.location = userGoogleAdminOmniauthAuthorizeUrl({
        state: this.google.id,
      });
    },

    successParam() {
      return !!new URLSearchParams(window.location.search).get('success');
    },
  },
};
</script>
