import { DateTime } from 'luxon';

/**
 * Round a JS date to the nearest interval (defaults to 15 minutes)
 *
 * @param {DateTime} date a luxon DateTime instance
 * @param {number} interval the interval to round to in milliseconds
 * @returns {DateTime}
 */
export function roundDate(date, interval) {
  const time = date.toMillis();
  return DateTime.fromMillis(Math.round(time / interval) * interval);
}

/**
 * Get a future date rounded to the nearest 15 minute interval
 *
 * @param {DateTime} date a luxon DateTime instance
 * @returns {DateTime}
 */
export function next15MinuteInterval(date) {
  const interval = 1000 * 60 * 15;
  return roundDate(date.plus({ milliseconds: interval }), interval);
}
