import { DirectUpload } from '@rails/activestorage';
import { isFunction, isString } from 'lodash-es';

import {
  directUploadsUrl,
  railsServiceBlobPath,
  uploadsDirectImageUploadsUrl,
} from 'routes';

export const isImage = (mimeType) => {
  return isString(mimeType) && mimeType.startsWith('image/');
};

/**
 * Generic rails file uploader
 */
const railsUpload = async (file, progressCallback) => {
  const uploadUrl = isImage(file?.type)
    ? uploadsDirectImageUploadsUrl()
    : directUploadsUrl();

  // use delegate to track progress
  const delegate = {
    directUploadWillStoreFileWithXHR: (xhr) => {
      if (isFunction(progressCallback)) {
        xhr.upload.addEventListener('progress', (event) => {
          const percent = (event.loaded / event.total) * 100;
          progressCallback(percent);
        });
      }
    },
  };

  // wrap DirectUpload in a promise
  return new Promise((resolve, reject) => {
    try {
      const upload = new DirectUpload(file, uploadUrl, delegate);

      upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * Upload adapter for Formulate
 */
export const formulateUpload = async (
  file,
  progressCallback,
  errorCallback
) => {
  if (!file) {
    return false;
  }

  try {
    // If we're rendering an edit form, the file will already exist and will be passed in as an object.
    // Rails is expecting the file input to be the signed id of the object placed on ActiveStorage
    // Here, if we get an object instead of the file, we should immediately resolve with the signed ID so rails knows what to do
    // Example that should be passed in to the value of existing file/image inputs:
    // [{ url: ..., signedId: ... }]
    // https://vueformulate.com/guide/inputs/types/file/#setting-initial-values
    // See formulate documentation for more info
    const isUploaded = file instanceof File && 'signedId' in file;

    const uploadResult = isUploaded
      ? file
      : await railsUpload(file, progressCallback);

    return { signedId: uploadResult.signed_id };
  } catch (e) {
    errorCallback(e);
    return false;
  }
};

/**
 * Upload adapter for Trix
 */
export const trixUpload = async (attachment) => {
  const progressCallback = (p) => {
    attachment.setUploadProgress(p);
  };

  const blob = await railsUpload(attachment.file, progressCallback);
  const attributes = isImage(blob.content_type)
    ? {
        url: blob.image_url,
        href: blob.image_url,
        previewable: true,
      }
    : {
        url: railsServiceBlobPath(blob.signed_id, blob.filename),
        href: railsServiceBlobPath(blob.signed_id, blob.filename),
        previewable: false,
      };
  attachment.setAttributes(attributes);
};
