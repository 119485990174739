import { azureAdApiIndexPath, azureAdApiPath } from 'routes';

import ManageAzureAdApi from './manage-azure-ad-api/ManageAzureAdApi.vue';

export default {
  name: 'azure_ad_api',
  label: 'Azure Active Directory',
  type: 'data',
  manageComponent: ManageAzureAdApi,
  indexPath: azureAdApiIndexPath,
  path: azureAdApiPath,
};
