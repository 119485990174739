import PreviewMixin from 'mixins/preview-mixin';

import SlackNavImage from './slack-nav.svg';

export default {
  name: 'PreviewSlack',

  mixins: [PreviewMixin],

  components: { SlackNavImage },

  computed: {
    timestamp() {
      return this.updateDateTime.toFormat('t');
    },
  },
};
