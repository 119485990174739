import { oktaIndexPath, oktaPath } from 'routes';

import ManageOkta from './manage-okta/ManageOkta.vue';

export default {
  name: 'okta',
  label: 'Okta',
  type: 'data',
  manageComponent: ManageOkta,
  indexPath: oktaIndexPath,
  path: oktaPath,
};
