import PreviewSms from './preview-sms/PreviewSms.vue';

export default {
  name: 'text',
  label: 'SMS',
  type: 'delivery',
  core: true,
  previewComponent: PreviewSms,
  allowFeaturedImage: false,
  allowCustomMessage: true,
  customMessageMaxLength: 160,
  hexColor: '#A657D8',
};
