/* eslint-disable no-param-reassign */

/**
 * Directive to only show an element through the visibility attribute otherwise toggle to hidden.
 * @example <div v-visible="isExpanded" />
 */
export const vVisibleDirective = {
  install(Vue) {
    Vue.directive('visible', (el, binding) => {
      const { value } = binding;

      if (value) {
        el.style.visibility = 'visible';
      } else {
        el.style.visibility = 'hidden';
      }
    });
  },
};
