export default {
  name: 'ConfigureWorkvivoRadio',

  components: {},

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
};
