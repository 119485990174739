<template>
  <transition
    :enter-active-class="`transition-opacity ${enterDuration} ${enterDelay} ${enterTiming}`"
    :leave-active-class="`transition-opacity ${leaveDuration} ${leaveDelay} ${leaveTiming}`"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <slot />
  </transition>
</template>
<script>
export default {
  name: 'FadeTransition',

  props: {
    enterDuration: {
      type: String,
      default: 'duration-100',
    },
    leaveDuration: {
      type: String,
      default: 'duration-100',
    },
    enterDelay: {
      type: String,
      default: '',
    },
    leaveDelay: {
      type: String,
      default: '',
    },
    enterTiming: {
      type: String,
      default: 'ease-out',
    },
    leaveTiming: {
      type: String,
      default: 'ease-in',
    },
  },
};
</script>
