<template>
  <component
    :is="tag"
    :href="!disabled && href"
    :target="external && href ? '_blank' : undefined"
    :rel="external && href ? 'noreferrer' : undefined"
    :type="href ? undefined : 'button'"
    :class="[unstyled ? '' : 'font-medium text-blue-500 underline']"
    @click="handleClick"
  >
    <span
      v-if="hasAppendOrPrepend"
      :class="[hasAppendOrPrepend ? 'flex items-center' : '']"
    >
      <slot name="prepend"></slot>
      <slot></slot>
      <slot name="append"></slot>
    </span>
    <template v-else>
      <slot />
    </template>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: undefined,
      required: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    unstyled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tag() {
      if (this.disabled && this.href) return 'div';
      return this.href ? 'a' : 'button';
    },
    hasAppendOrPrepend() {
      return !!this.$slots.prepend || !!this.$slots.append;
    },
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>
