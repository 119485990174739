<template>
  <div class="py-14 text-center">
    <div class="relative">
      <div class="flex justify-center">
        <slot name="graphic" />
      </div>
      <div class="m-auto" :class="sizeClass">
        <span class="font-bold text-gray-700">
          <slot name="title"></slot>
        </span>
        <p class="mt-3">
          <slot name="message"></slot>
        </p>
        <div class="translate-y-6">
          <slot name="cta"></slot>
        </div>
        <transition v-if="!hasGraphic" name="fade" appear>
          <span data-testid="sparkles">
            <SparkleIcon
              class="absolute -top-12 right-44 w-3.5 animate-pulse text-blue-500"
            />
            <SparkleIconMd
              class="absolute -bottom-12 left-16 w-2 animate-pulse text-blue-500"
            />
            <SparkleIconSm
              class="absolute -top-4 right-16 w-1.5 animate-pulse text-blue-500"
            />
          </span>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SparkleIconMd from '@workshop/baja/assets/icons/icon-sparkle-outline-md.svg';
import SparkleIconSm from '@workshop/baja/assets/icons/icon-sparkle-outline-sm.svg';
import SparkleIcon from '@workshop/baja/assets/icons/icon-sparkle-outline.svg';

export const ContentPlaceholderProps = {
  sizes: ['md', 'lg', 'xl', '2xl'],
};

export default {
  name: 'ContentPlaceholder',

  components: {
    SparkleIcon,
    SparkleIconMd,
    SparkleIconSm,
  },

  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value) => ContentPlaceholderProps.sizes.includes(value),
    },
  },

  computed: {
    hasGraphic() {
      return !!this.$slots.graphic;
    },

    hasTitle() {
      return !!this.$slots.title;
    },

    sizeClass() {
      switch (this.size) {
        default:
        case 'md':
          return 'max-w-xs';
        case 'lg':
          return 'max-w-md';
        case 'xl':
          return 'max-w-lg';
        case '2xl':
          return 'max-w-xl';
      }
    },
  },
};
</script>
