import CircleIcon from '@workshop/baja/assets/icons/icon-circle.svg';
import Card from 'app/core/card/Card.vue';
import Title from 'app/core/title/Title.vue';
import PreviewMixin from 'mixins/preview-mixin';

export default {
  name: 'PreviewWorkvivo',

  mixins: [PreviewMixin],

  components: { Card, CircleIcon, Title },
};
