import { capitalize, lowerCase } from 'lodash-es';

import { toSentence } from 'utils/strings/to-sentence';

/**
 * Returns true if an email address is valid
 * Overwrites the default Formulate email validation rule
 *
 * This validation is taken from our back end validation
 * and should be kept in sync as much as possible
 */
export function emailValidator({ value }) {
  /* eslint-disable no-control-regex */
  const emailRegex =
    /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

  return emailRegex.test(value);
}

/**
 * Returns true if a domain is valid, i.e. example.com.
 * This validation is basically the second half of the email validation
 * and should be kept in sync with the back end as much as possible
 */
export function domainValidator({ value }) {
  /* eslint-disable no-control-regex */
  const domainRegex =
    /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

  return domainRegex.test(value);
}
export function domainMessage({ value }) {
  return `${value} is an invalid domain.`;
}

/**
 * Very loose phone number validation
 * The back end will perform much more strict validation using libphonumber so we should
 * support displaying validation errors from the back end on all phone number fields
 */
export function phoneNumberValidator({ value }) {
  const normalizedNumber = value.replace(/[\s\-().+]+/g, '');
  const digitCount = normalizedNumber.length;
  return digitCount >= 7 && digitCount <= 20 && !/\D/.test(normalizedNumber);
}
export function phoneNumberMessage({ value }) {
  return `"${value}" is not a valid phone number.`;
}

/**
 * Validates field value contains at least one symbol
 * Returns true if the field value contains at least one symbol
 */
export function requireOneSymbolValidator({ value }) {
  const symbolRegex = /(?=.*\W)|(?=.*_)/;
  return symbolRegex.test(value);
}

export function requireOneSymbolMessage(context) {
  return `${capitalize(
    context.name || 'This field'
  )} must contain at least one symbol.`;
}

/**
 * Validates field value contains at least one number
 */
export function requireOneNumberValidator({ value }) {
  const numberRegex = /(?=.*\d)/;
  return numberRegex.test(value);
}
export function requireOneNumberMessage(context) {
  return `${capitalize(context.name)} must contain at least one number.`;
}

/**
 * Validates at least one of the provided fields has a value
 */
export function requireOneValidator(context, ...fieldNames) {
  const formValues = context.getFormValues();

  return fieldNames.some((fieldName) => !!formValues[fieldName]);
}
export function requireOneMessage(context) {
  const fieldNames = context.args.map(lowerCase);
  return `Please enter a valid ${toSentence(fieldNames, 'or')}.`;
}
