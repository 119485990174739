import { teamPath, teamsPath } from 'routes';

import ConfigureTeams from './ConfigureTeams.vue';
import ManageTeams from './manage-teams/ManageTeams.vue';
import PreviewTeams from './preview-teams/PreviewTeams.vue';

export default {
  name: 'teams',
  label: 'Teams',
  type: 'delivery',
  manageComponent: ManageTeams,
  indexPath: teamsPath,
  path: teamPath,
  configureComponent: ConfigureTeams,
  previewComponent: PreviewTeams,
  allowFeaturedImage: false,
  allowCustomMessage: true,
  customMessageMaxLength: 320,
  hexColor: '#4b53bc',
};
