export function deserializeFileUpload(apiData, inputName) {
  if (
    apiData &&
    `${inputName}Url` in apiData &&
    `${inputName}SignedId` in apiData
  ) {
    return [
      {
        url: apiData[`${inputName}Url`],
        signedId: apiData[`${inputName}SignedId`],
      },
    ];
  }
  return null;
}

export function serializeFileUpload(value) {
  if (!Array.isArray(value)) {
    return value;
  }
  if (!value.length) {
    return null;
  }
  return value[0].signedId;
}
