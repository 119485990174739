/* eslint-disable no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { getCsrfToken, refreshCsrfToken } from 'lib/api-helpers/csrf-token';
import { environment, requestId } from 'lib/document-helpers/document-meta';
import { rootPath, rootUrl } from 'routes';

export const HEADERS = {
  CSRF: 'X-CSRF-Token',
  REQUESTED_WITH: 'X-Requested-With',
  REQUEST_ID: 'X-Request-Id',
};

function isInternalApi(url) {
  return url?.startsWith(rootPath()) || url?.startsWith(rootUrl());
}

function isExternalWorkshopApi(url) {
  if (!url) {
    return false;
  }

  const env = environment();
  return (
    (env?.EMAIL_TRACKING_URL && url.startsWith(env.EMAIL_TRACKING_URL)) ||
    (env?.CONTENT_SERVICES_URL && url.startsWith(env.CONTENT_SERVICES_URL))
  );
}

/**
 * When a request to a Workshop endpoint fails with a 403,
 * refresh the CSRF token and retry the request.
 */
async function handle403(failedRequest) {
  // external API calls should fail normally
  if (!isInternalApi(failedRequest.config.url)) {
    throw failedRequest.response;
  }

  const csrfToken = await refreshCsrfToken();
  if (csrfToken) {
    failedRequest.response.config.headers[HEADERS.CSRF] = csrfToken;
  } else {
    throw failedRequest.response;
  }
}

/**
 * Apply Workshop specific request headers
 */
function handleRequest(request) {
  if (isExternalWorkshopApi(request.url)) {
    // send request id
    request.headers[HEADERS.REQUEST_ID] = requestId();
  }

  if (isInternalApi(request.url)) {
    // used by Rails to check if it is a valid XHR request
    request.headers[HEADERS.REQUESTED_WITH] = 'XMLHttpRequest';
    // add CSRF token
    request.headers[HEADERS.CSRF] = getCsrfToken();
  }

  return request;
}

export default function initializeAxios() {
  axios.interceptors.request.use(handleRequest);
  createAuthRefreshInterceptor(axios, handle403, { statusCodes: [403] });
}
