import VuePortal from '@linusborg/vue-simple-portal';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueShortkey from 'vue-shortkey';
import Vuex from 'vuex';

import GlobalWorkshopData from 'lib/vue-helpers/global-workshop-data';
import { vHiddenCollapseDirective } from 'lib/vue-helpers/v-hidden-collapse';
import { vVisibleDirective } from 'lib/vue-helpers/v-visible';

export default () => {
  Vue.use(Vuex);
  Vue.use(VueRouter);
  Vue.use(VueShortkey);
  Vue.use(GlobalWorkshopData);
  Vue.use(vHiddenCollapseDirective);
  Vue.use(vVisibleDirective);
  Vue.use(VuePortal, {
    name: 'Portal',
  });
};
