import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Button from 'app/core/button/Button.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';

import ManageWorkvivoForm from './ManageWorkvivoForm.vue';

export default {
  name: 'ManageWorkvivo',

  components: {
    AppPlaceholder,
    Button,
    LoadingIcon,
    ManageWorkvivoForm,
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'error',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      workvivo: 'connectedData',
    }),
    isFirstConfiguration() {
      return this.workvivo.status === 'pending_activation';
    },
  },

  watch: {
    error(newValue) {
      if (newValue) {
        this.$formulate.handle(newValue, 'editWorkvivo');
      }
    },
  },

  data() {
    return {
      isModeReconfigure: false,
    };
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected', 'updateConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },
    handleReconfigure() {
      this.isModeReconfigure = true;
    },
    async handleSubmit(payload) {
      const isSuccess = await this.updateConnected({
        type: this.app.type,
        params: payload,
      });

      if (isSuccess) {
        this.isModeReconfigure = false;
        this.showSuccessToast();
      } else {
        this.showErrorToast();
      }
    },
    showSuccessToast() {
      this.$toast.success('Workvivo settings saved successfully', {
        type: 'success',
      });
    },

    showErrorToast() {
      this.$toast('Unable to save settings', { type: 'error' });
    },
  },
};
