import { isFunction } from 'lodash-es';
/**
 * Adds infinite pagination data and helpers.
 */
export default ({ isLoadingPropName }) => ({
  props: {
    /** Whether to enable auto pagination when scrolling  */
    searchEndpointInfinite: {
      type: [Function, Boolean],
      default: undefined,
    },
  },

  data() {
    return {
      infinitePaginationInfo: undefined,
      infiniteQueryLast: undefined,
    };
  },

  computed: {
    infiniteStatus() {
      const { infinitePaginationInfo, searchEndpointInfinite } = this;

      if (!searchEndpointInfinite || !infinitePaginationInfo) {
        return undefined;
      }

      const hasMore =
        infinitePaginationInfo.currentPage < infinitePaginationInfo.totalPages;
      return this[isLoadingPropName] ? 'loading' : hasMore;
    },
  },

  methods: {
    dataFn(data) {
      return data.results;
    },
    /**
     * Process and return endpoint query and page details if & when enabled and all conditions are met.
     */
    processInfiniteScrollInfo(scrollInfo) {
      const {
        infinitePaginationInfo,
        infiniteQueryLast,
        searchEndpointInfinite,
      } = this;

      if (
        searchEndpointInfinite &&
        !this[isLoadingPropName] &&
        infinitePaginationInfo &&
        infinitePaginationInfo.currentPage <
          infinitePaginationInfo.totalPages &&
        scrollInfo?.infiniteYFromVisible <= 0 &&
        scrollInfo?.scrollPercY
      ) {
        // return next page info
        return {
          query: infiniteQueryLast,
          page: infinitePaginationInfo.currentPage + 1,
        };
      }

      return null;
    },
    /**
     * Processes initial data (to be placed first within the input change handler).
     */
    processInputChangeInfiniteQuery(query) {
      this.infiniteQueryLast = query;
    },
    /**
     * Processes response data and either appends that to previous data if paging or returns new set.
     */
    processInputChangeInfiniteOptions(data, previousData) {
      const { searchEndpointInfinite } = this;

      this.infinitePaginationInfo = searchEndpointInfinite
        ? data.paginationInfo
        : undefined;

      if (!searchEndpointInfinite) {
        return data;
      }

      const { infinitePaginationInfo } = this;

      return [
        ...(!infinitePaginationInfo || infinitePaginationInfo.currentPage === 1
          ? []
          : previousData),
        ...(isFunction(searchEndpointInfinite)
          ? searchEndpointInfinite(data)
          : this.dataFn(data)),
      ];
    },
    processInputChangeInfiniteParams(page) {
      return this.searchEndpointInfinite ? { page } : undefined;
    },
  },
});
