import { sharepointIndexPath, sharepointPath } from 'routes';

import ConfigureSharepoint from './ConfigureSharepoint.vue';
import ManageSharepoint from './manage-sharepoint/ManageSharepoint.vue';
import PreviewSharepoint from './preview-sharepoint/PreviewSharepoint.vue';

export default {
  name: 'sharepoint',
  label: 'Sharepoint',
  type: 'delivery',
  manageComponent: ManageSharepoint,
  indexPath: sharepointIndexPath,
  path: sharepointPath,
  previewComponent: PreviewSharepoint,
  configureComponent: ConfigureSharepoint,
  allowFeaturedImage: false,
  allowCustomMessage: true,
  customMessageMaxLength: 320,
  hexColor: '#00C9D2',
};
