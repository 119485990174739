<template>
  <td
    :class="[{ synced: synced, full: full, check: check }, alignClass]"
    v-bind="$listeners"
  >
    <slot />
  </td>
</template>

<script>
export default {
  name: 'TableData',

  props: {
    align: {
      type: String,
      default: 'left',
      required: false,
    },
    check: {
      type: Boolean,
      default: false,
      required: false,
    },
    full: {
      type: Boolean,
      default: false,
      required: false,
    },
    synced: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    alignClass() {
      switch (this.align) {
        case 'right':
          return 'text-right';
        case 'center':
          return 'text-center';
        default:
          return 'text-left';
      }
    },
  },
};
</script>
