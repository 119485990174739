export default [
  'adp_workforce_now',
  'bamboohr',
  'freshteam',
  'gusto',
  'hr_cloud',
  'hr_partner',
  'hibob',
  'humaansio',
  'justworks',
  'lano',
  'nmbrs',
  'paychex',
  'paylocity',
  'personio',
  'rippling',
  'sap_successfactors',
  'sage_hr',
  'sapling',
  'square_payroll',
  'trinet',
  'ukg_ready',
  'zenefits',
];
