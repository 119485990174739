import { searchChannelsPath } from 'routes';

import ConfigureWorkvivoRadio from './radio/ConfigureWorkvivoRadio.vue';
import WorkvivoAutocompleteOption from './WorkvivoAutocompleteOption.vue';

const AUDIENCE_TYPE = {
  EVERYONE: 'everyone',
  SPACES: 'spaces',
  TEAMS: 'teams',
};

const CHANNEL_TYPE = {
  SPACE: 0,
  TEAM: 1,
};

export default {
  name: 'ConfigureWorkvivo',

  components: { ConfigureWorkvivoRadio, WorkvivoAutocompleteOption },

  props: {
    /**
     * The dynamically generated delivery method's form data for this app.
     */
    deliveryDetailsFormData: {
      type: Object,
      required: true,
    },
    deliveryMethod: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    previewText: {
      type: String,
      required: true,
    },
    subjectLine: {
      type: String,
      required: true,
    },
  },

  watch: {
    /**
     * As a less invasive way of conditionally setting title & subtitle to their synced defaults, listen for when a user enables the workvivo notification channel and then check.
     * This is a better alternative to doing this on `mounted` which could trigger an autosave on email settings page load even prior to enabling this notification channel.
     */
    disabled: function handler(newValue) {
      if (!newValue) {
        // if no subtitle form data (most likely on first load of a new email or edge case where an empty input was saved)
        if (!this.deliveryDetailsFormData.subtitle) {
          this.updateDeliveryDetailsFormDataField(
            'subtitle',
            'previewText',
            undefined
          );
        }

        // if no title form data (most likely on first load of a new email or edge case where an empty input was saved)
        if (!this.deliveryDetailsFormData.title) {
          this.updateDeliveryDetailsFormDataField(
            'title',
            'subjectLine',
            undefined
          );
        }
      }
    },
    /**
     * Bind Preview Text field to Subtitle field.
     */
    previewText: function handler(_newValue, oldValue) {
      if (!this.disabled) {
        this.updateDeliveryDetailsFormDataField(
          'subtitle',
          'previewText',
          oldValue
        );
      }
    },
    /**
     * Bind Subject Line field to Title field.
     */
    subjectLine: function handler(_newValue, oldValue) {
      if (!this.disabled) {
        this.updateDeliveryDetailsFormDataField(
          'title',
          'subjectLine',
          oldValue
        );
      }
    },
  },

  methods: {
    updateDeliveryDetailsFormDataField(fieldKey, syncToPropName, oldValue) {
      const shouldSyncFieldValues =
        !this.deliveryDetailsFormData[fieldKey] ||
        this.deliveryDetailsFormData[fieldKey] === oldValue;

      if (shouldSyncFieldValues) {
        // update workvivo field to mirror email settings prop
        // using email settings prop instead of 'newValue' from watch handler b/c of timeout and outdated value
        this.deliveryDetailsFormData[fieldKey] = this[syncToPropName];
      }
    },
  },

  computed: {
    isAudienceEveryone() {
      return this.selectedAudience === AUDIENCE_TYPE.EVERYONE;
    },
    isAudienceSpaces() {
      return this.selectedAudience === AUDIENCE_TYPE.SPACES;
    },
    isAudienceTeams() {
      return this.selectedAudience === AUDIENCE_TYPE.TEAMS;
    },
    selectedAudience() {
      return this.deliveryDetailsFormData.audience;
    },
    searchEndpoint() {
      return searchChannelsPath({
        search: {
          appId: this.deliveryMethod.id,
          appType: 'workvivo',
        },
      });
    },
  },

  data() {
    return {
      CHANNEL_TYPE,
      radioOptions: {
        [AUDIENCE_TYPE.EVERYONE]: 'Everyone',
        [AUDIENCE_TYPE.SPACES]: 'Spaces',
        [AUDIENCE_TYPE.TEAMS]: 'Teams',
      },
    };
  },
};
