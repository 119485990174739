import axios from 'axios';

import {
  getDocumentMeta,
  setDocumentMeta,
} from 'lib/document-helpers/document-meta';
import { refreshCsrfIndexPath } from 'routes';

export async function refreshCsrfToken() {
  // skipAuthRefresh prevents a 403 on this request from being retried (which could result in an infinite loop)
  const { data } = await axios.get(refreshCsrfIndexPath(), {
    skipAuthRefresh: true,
  });

  setDocumentMeta('csrf-token', data);
  return data;
}

export const getCsrfToken = () => getDocumentMeta('csrf-token');
