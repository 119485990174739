// Function that accepts a number and options, and returns a string
// of the original number formatted with commas, and/or a suffix ('k', 'm', 'b', 't', etc.)
// This is a wrapper function for the Intl.NumberFormat API
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat

export default (number, options) => {
  // if the number is null, undefined, NaN,
  // Number.POSITIVE_INFINITY, or Number.NEGATIVE_INFINITY
  // return 'N/A
  if (
    number === null ||
    number === undefined ||
    Number.isNaN(number) ||
    number === Number.POSITIVE_INFINITY ||
    number === Number.NEGATIVE_INFINITY
  ) {
    return 'N/A';
  }

  // If the number is greater than 10000
  // and { short: true } option is passed
  // shorten it with a suffix
  if (Math.abs(Math.round(number)) > 9999 && options?.short) {
    const formatter = Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
      maximumSignificantDigits: 3,
      minimumSignificantDigits: 2,
      trailingZeroDisplay: 'stripIfInteger',
    });
    return formatter.format(number).toLowerCase();
  }

  // If the number is less than 10000, just format it with commas
  return Intl.NumberFormat().format(number);
};
