import { googleIndexPath, googlePath } from 'routes';

import Google from './manage-google/ManageGoogle.vue';

export default {
  name: 'google',
  label: 'Google Directory Sync',
  type: 'data',
  manageComponent: Google,
  indexPath: googleIndexPath,
  path: googlePath,
};
