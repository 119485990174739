import axios from 'axios';
import { isFunction } from 'lodash-es';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import SearchIcon from '@workshop/baja/assets/icons/icon-search.svg';
import XIcon from '@workshop/baja/assets/icons/icon-x.svg';
import FadeTransition from 'app/brand/transitions/FadeTransition.vue';
import Link from 'app/core/link/Link.vue';
import AutocompleteInput, {
  AutocompleteInputProps,
} from 'app/formulate/autocomplete-input/AutocompleteInput.vue';
import paginationInfiniteMixin from 'mixins/pagination-infinite-mixin';

export default {
  name: 'Autocomplete',
  components: {
    AutocompleteInput,
    FadeTransition,
    Link,
    LoadingIcon,
    SearchIcon,
    XIcon,
  },

  props: {
    behavior: {
      type: String,
      default: 'select',
    },
    context: {
      type: Object,
      required: true,
    },
    searchEndpoint: {
      type: String,
      default: undefined,
    },
    searchFunction: {
      type: Function,
      default: undefined,
    },
    searchParams: {
      type: Object,
      default: undefined,
    },
    searchQuery: {
      type: String,
      default: undefined,
    },
    searchVerb: {
      type: String,
      default: 'get',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (val) => AutocompleteInputProps.variants.includes(val),
    },
  },

  mixins: [paginationInfiniteMixin({ isLoadingPropName: 'isLoading' })],

  data() {
    return {
      options: [],
      isLoading: false,
      hasFocus: false,
    };
  },

  computed: {
    model() {
      return this.context.model;
    },
  },

  methods: {
    clearSelection() {
      this.context.model = '';
    },

    handleScrollInfo(scrollInfo) {
      const nextPageInfo = this.processInfiniteScrollInfo(scrollInfo);

      if (nextPageInfo) {
        this.handleInputChange(nextPageInfo.query, nextPageInfo.page);
      }
    },

    handleSelected(value, item) {
      this.context.model = value;
      this.options = [];
      this.$emit('select', value, item);
      this.$emit('change', this.context.model);
    },

    async handleInputChange(query, page = 1) {
      this.processInputChangeInfiniteQuery(query);

      if (this.behavior === 'input') {
        this.context.model = query;
        this.$emit('change', this.context.model);
      }

      if (isFunction(this.searchFunction)) {
        this.options = await this.searchFunction(query, {
          searchParams: this.searchParams,
          searchQuery: this.searchQuery,
          searchVerb: this.searchVerb,
          searchEndpoint: this.searchEndpoint,
        });
      } else if (this.searchEndpoint) {
        const isPost = this.searchVerb === 'post';
        const params = {
          ...this.processInputChangeInfiniteParams(page),
          ...this.searchParams,
        };

        if (!this.searchQuery) {
          params.q = query;
        } else if (isPost) {
          params.q = { [this.searchQuery]: query };
        } else if (this.searchQuery) {
          params[`q[${this.searchQuery}]`] = query;
        }

        this.isLoading = true;
        const resp = isPost
          ? await axios.post(this.searchEndpoint, params)
          : await axios.get(this.searchEndpoint, { params });
        this.options = this.processInputChangeInfiniteOptions(
          resp.data,
          this.options
        );
        this.isLoading = false;
      } else {
        this.options = [];
      }
    },
  },
};
