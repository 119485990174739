import CheckmarkIcon from '@workshop/baja/assets/icons/icon-checkmark-full.svg';
import IconWarn from '@workshop/baja/assets/icons/icon-exclamation-warn.svg';
import InfoIcon from '@workshop/baja/assets/icons/icon-info.svg';
import XIcon from '@workshop/baja/assets/icons/icon-x-full.svg';
import CloseIcon from '@workshop/baja/assets/icons/icon-x.svg';
import Button from 'app/core/button/Button.vue';
import Title from 'app/core/title/Title.vue';

export const AlertProps = {
  severity: ['error', 'warning', 'info', 'success'],
};

export const AlertColors = {
  error: {
    bg: 'bg-red-500',
    icon: 'text-red-500',
  },
  warning: {
    bg: 'bg-yellow-500',
    icon: 'text-yellow-500',
  },
  info: {
    bg: 'bg-blue-500',
    icon: 'text-blue-500',
  },
  success: {
    bg: 'bg-green-500',
    icon: 'text-green-500',
  },
};

export default {
  name: 'Alert',

  components: {
    Button,
    CheckmarkIcon,
    CloseIcon,
    IconWarn,
    InfoIcon,
    Title,
    XIcon,
  },

  props: {
    buttonText: {
      type: String,
      default: 'OK',
      required: false,
    },

    buttonHref: {
      type: String,
      default: '',
      required: false,
    },

    hideCloseButton: {
      type: Boolean,
      default: false,
    },

    heading: {
      type: String,
      default: 'Attention needed',
    },

    severity: {
      type: String,
      default: 'info',
      validator: (val) => AlertProps.severity.includes(val),
    },

    show: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      AlertColors,
    };
  },

  computed: {
    hasSlotContent() {
      return !!this.$slots.default;
    },
  },

  methods: {
    requestClose() {
      if (!this.hideCloseButton) {
        this.$emit('hide');
      }
    },
  },
};
