import * as Sentry from '@sentry/browser';

/**
 * Returns an array of HTML elements with a matching id or data attribute
 */
function getElementsForModuleId(moduleId) {
  // new system: <div data-vue-module="module-id">
  const isComponentModule = moduleId.includes('individual-component');
  const elementsByAttribute = isComponentModule
    ? document.querySelectorAll('[data-vue-component]')
    : document.querySelectorAll(`[data-vue-module="${moduleId}"]`);
  const elements = [...elementsByAttribute];

  // old system: <div id="module-id">
  const elementById = document.getElementById(moduleId);
  if (elementById) {
    elements.push(elementById);
  }

  return elements;
}

/**
 * Initialize a lazy loaded module for a given element
 */
function initModule(moduleId, moduleFunction, element) {
  if (document.body.contains(element)) {
    moduleFunction(element);
  } else {
    // this might happen if we navigate away while the script is loading
    Sentry.captureMessage(
      `Skipping initialization for #${moduleId} because it has been removed from the DOM`,
      { level: 'info' }
    );
  }
}

/**
 * On page load, find elements with IDs matching a module name then lazy load and instantiate the module
 * e.g. a module named my-module.js will be instantiated for an element with id="my-module" or data-vue-module="my-module"
 *
 * There is a correlation between modules and webpack chunks but they may be split or combined
 * depending on spitChunks configuration
 */
export default () => {
  const context = require.context(
    '../../../vue-modules',
    false,
    /\.js$/i,
    'lazy'
  );

  context.keys().forEach(async (key) => {
    const moduleId = key.split('/').pop().split('.')[0].replace(/_/g, '-');
    const elements = getElementsForModuleId(moduleId);

    if (elements.length) {
      const module = await context(`${key}`);
      const moduleFunction = module.default;

      if (typeof moduleFunction !== 'function') {
        throw new Error(`Module "${key}" does not export a default function`);
      }

      elements.forEach((element) =>
        initModule(moduleId, moduleFunction, element)
      );
    }
  });
};
