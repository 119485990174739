<template>
  <ContentPlaceholder class="my-10">
    <template #title
      >To start configuring {{ app.name }},<br />add the app to
      Workshop.</template
    >
    <template #message>{{ app.cta }}</template>
    <template #cta>
      <Button
        variant="secondary"
        :disabled="isLoading"
        @click="handleAddAppClick"
      >
        Add app
      </Button>
    </template>
  </ContentPlaceholder>
</template>

<script>
import Button from 'app/core/button/Button.vue';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';

export default {
  name: 'AppPlaceholder',

  components: {
    Button,
    ContentPlaceholder,
  },

  props: {
    app: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleAddAppClick() {
      this.$emit('add-app');
    },
  },
};
</script>
