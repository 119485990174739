var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tagOrDefault,{tag:"component",class:[
    'relative inline-flex select-none items-center justify-center rounded-md font-bold ',
    _vm.colorClasses,
    _vm.sizeClasses,
    {
      'mr-2': _vm.showWarnIcon,
      'hover:cursor-pointer': _vm.interactive && !_vm.disabled,
      'is-interactive': _vm.interactive,
      group: _vm.interactive,
    } ],attrs:{"disabled":_vm.disabled,"href":_vm.href,"target":_vm.href && _vm.external ? '_blank' : undefined,"rel":_vm.href && _vm.external ? 'noreferrer' : undefined},on:{"click":_vm.handleClick}},[_c('div',{staticClass:"flex max-w-md items-center py-2",class:_vm.showRemoveButton ? 'pl-3 pr-0' : 'px-3'},[_vm._t("prepend"),_vm._v(" "),(_vm.icon)?_c(_vm.icon,{tag:"component",staticClass:"mr-2 shrink-0",class:_vm.iconSizeClass,attrs:{"data-testid":"badgeIcon"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"truncate"},[_vm._t("default")],2),_vm._v(" "),(_vm.sourceIcon)?_c(_vm.sourceIcon,{tag:"component",class:[_vm.sourceIconClasses, _vm.colorClasses, 'ml-2'],attrs:{"data-testid":"sourceIcon"}}):_vm._e(),_vm._v(" "),_vm._t("append")],2),_vm._v(" "),(_vm.showWarnIcon)?_c('ExclamationWarnIcon',{staticClass:"absolute -right-2 -top-2 w-4 text-red-500",attrs:{"data-testid":"warnIcon"}}):_vm._e(),_vm._v(" "),(_vm.showRemoveButton)?[(_vm.locked)?_c('Tooltip',{staticClass:"leading-none",attrs:{"width":215,"data-testid":"lockedTooltip","interactive-trigger":true},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [(_vm.source === 'synced')?_c('p',[_vm._v("\n          This "+_vm._s(_vm.model)+" is synced externally and cannot be edited.\n        ")]):(_vm.source === 'automatic')?_c('p',[_vm._v("\n          This "+_vm._s(_vm.model)+" is populated automatically and cannot\n          be edited.\n        ")]):_c('p',[_vm._v("This "+_vm._s(_vm.model)+" cannot be edited.")])]},proxy:true}],null,false,603381225)},[_c('BadgeRemoveButton',{attrs:{"disabled":true,"icon-color-class":_vm.colorClasses}})],1):_c('BadgeRemoveButton',{attrs:{"disabled":_vm.disabled,"icon-color-class":_vm.colorClasses},on:{"click":_vm.handleRemoveClick}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }