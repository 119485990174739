import Button from 'app/core/button/Button.vue';

export default {
  name: 'ManageWorkvivoForm',

  components: {
    Button,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    workvivo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formData: null,
    };
  },

  watch: {
    workvivo: {
      immediate: true,
      handler(value) {
        const { baseUrl, channelsApiKey, newsApiKey, workvivoId } = value;
        this.formData = {
          baseUrl,
          channelsApiKey,
          newsApiKey,
          workvivoId,
        };
      },
    },
  },

  methods: {
    async submitHandler(data) {
      const { baseUrl, channelsApiKey, newsApiKey, workvivoId } = data;
      const payload = {
        baseUrl,
        channelsApiKey,
        newsApiKey,
        workvivoId,
      };

      this.$emit('submit', payload);
      this.$formulate.reset('editWorkvivo', this.formData);
    },
  },
};
