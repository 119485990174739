<template>
  <div v-if="showIfEnabled || showIfDisabled">
    <slot></slot>
  </div>
</template>

<script>
import { isUndefined } from 'lodash-es';

export default {
  name: 'Trebuchet',

  props: {
    featureEnabled: {
      type: String,
      default: undefined,
    },
    featureDisabled: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    showIfEnabled() {
      return (
        !isUndefined(this.featureEnabled) &&
        this.isTrebuchetEnabled(this.featureEnabled)
      );
    },

    showIfDisabled() {
      return (
        !isUndefined(this.featureDisabled) &&
        !this.isTrebuchetEnabled(this.featureDisabled)
      );
    },
  },

  created() {
    if (
      !isUndefined(this.featureEnabled) &&
      !isUndefined(this.featureDisabled)
    ) {
      throw new Error(
        'Ope! Trebuchets cannot have both a featureEnabled and featureDisabled prop.'
      );
    }
  },

  methods: {
    isTrebuchetEnabled(trebuchet) {
      return this.currentCompany.enabledTrebuchets.includes(trebuchet);
    },
  },
};
</script>
