import { isObjectLike, isString } from 'lodash-es';

import AutocompleteInput from 'app/formulate/autocomplete-input/AutocompleteInput.vue';

export default {
  name: 'EmailPicker',

  components: {
    AutocompleteInput,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
    splitOutput: {
      type: Boolean,
      default: false,
    },
    domains: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedDomainName: '',
      username: '',
      inputHasFocus: false,
    };
  },

  computed: {
    inputName() {
      return `${this.context.attributes.name}-username`;
    },

    inputId() {
      return `${this.context.attributes.id}-username`;
    },

    selectName() {
      return `${this.context.attributes.name}-domain`;
    },

    selectId() {
      return `${this.context.attributes.id}-domain`;
    },

    defaultDomainName() {
      if (this.domains.length) {
        const [firstDomain] = this.domains;
        return firstDomain.domainName;
      }
      return null;
    },

    selectedDomain() {
      return this.domainFromName(this.selectedDomainName);
    },

    isUsernameLocked() {
      return !!this.selectedDomain?.lockedEmailAddress;
    },
  },

  watch: {
    'context.model': {
      immediate: true,
      handler(value) {
        const { username, domainName } = this.parseModelValue(value);
        this.username = username.toLowerCase();
        this.selectedDomainName = domainName || this.defaultDomainName;
        this.checkForLockedEmailAddress();
      },
    },
  },

  methods: {
    domainFromId(domainId) {
      return domainId ? this.domains.find((d) => d.id === domainId) : null;
    },

    domainFromName(domainName) {
      return domainName
        ? this.domains.find((d) => d.domainName === domainName)
        : null;
    },

    checkForLockedEmailAddress() {
      if (this.isUsernameLocked) {
        const [username] = this.selectedDomain.lockedEmailAddress.split('@');
        this.username = username;
      }
    },

    parseModelValue(value) {
      let username = '';
      let selectedDomainName = null;

      if (this.splitOutput && isObjectLike(value)) {
        username = value.username;
        selectedDomainName =
          this.domainFromId(value.domainId)?.domainName || null;
      }

      if (!this.splitOutput && isString(value)) {
        let domainName;
        [username, domainName] = value.split('@');
        selectedDomainName = this.domainFromName(domainName)
          ? domainName
          : null;
      }

      return {
        username: username || '',
        domainName: selectedDomainName || this.selectedDomainName,
      };
    },

    getModelValue(trimWhiteSpace = false) {
      if (!this.selectedDomain || !this.username) {
        return this.splitOutput ? null : '';
      }

      const username = trimWhiteSpace
        ? this.username.trim().toLowerCase()
        : this.username.toLowerCase();

      return this.splitOutput
        ? {
            username,
            domainId: this.selectedDomain.id,
          }
        : `${username}@${this.selectedDomainName}`;
    },

    updateModel(trimWhiteSpace = false) {
      this.context.model = this.getModelValue(trimWhiteSpace);
      this.$emit('change', this.context.model);
    },

    async handleInputChange(event) {
      // strip domain from input in case one was pasted or autocompleted
      const [username] = (event.target.value || '').split('@');
      this.username = username;
      this.updateModel();
    },

    handleInputFocus() {
      this.inputHasFocus = true;
    },

    handleInputBlur(event) {
      this.inputHasFocus = false;
      this.updateModel(true);
      this.context.blurHandler(event);
    },

    handleDomainChange() {
      this.checkForLockedEmailAddress();
      this.updateModel();
    },

    handleDomainBlur(event) {
      this.context.blurHandler(event);
    },
  },
};
