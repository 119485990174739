<template>
  <FormulateInput
    name="channel"
    type="autocomplete"
    variant="secondary"
    behavior="select"
    :disabled="disabled"
    :validation="disabled ? 'optional' : 'required'"
    validation-name="A Sharepoint channel"
    label="Channel"
    placeholder="Search Channels..."
    search-query="channel_name_cont"
    :search-endpoint="searchEndpoint"
    :label-class="['!mt-0']"
    :outer-class="[disabled ? 'editing' : '', 'formulate-input--secondary']"
    @change="handleChannelChange"
  />
</template>
<script>
import { searchChannelsPath } from 'routes';

export default {
  name: 'ConfigureSharepoint',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    deliveryMethod: {
      type: Object,
      required: true,
    },
  },

  computed: {
    searchEndpoint() {
      return searchChannelsPath({
        search: {
          appId: this.deliveryMethod.id,
          appType: 'sharepoint',
        },
      });
    },
  },

  methods: {
    handleChannelChange() {
      this.$emit('change');
    },
  },
};
</script>
