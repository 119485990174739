const cache = new Map();

export const getDocumentMeta = (name, asJson = false) => {
  if (cache.has(name)) {
    return cache.get(name);
  }

  const el = document.head.querySelector(`[name='${name}']`);
  if (el === null || el === undefined) {
    return null;
  }

  const value = asJson ? JSON.parse(el.content) : el.content;
  cache.set(name, value);
  return value;
};

export const setDocumentMeta = (name, value) => {
  cache.set(name, value);
};

export const environment = () => getDocumentMeta('environment', true);
export const currentCompany = () => getDocumentMeta('current-company', true);
export const currentUser = () => getDocumentMeta('current-user', true);
export const sentryConfig = () => getDocumentMeta('sentry', true);
export const topNavConfig = () => getDocumentMeta('top-nav', true);
export const requestId = () => getDocumentMeta('request-id', false);
export const urgentAlert = () => getDocumentMeta('urgent-alert', true);
