import XIcon from '@workshop/baja/assets/icons/icon-x.svg';
import Button from 'app/core/button/Button.vue';
import FocusTrap from 'app/utilities/focus-trap/FocusTrap.vue';

let modalCount = 0;

export const ModalProps = {
  sizes: ['base', 'small', 'wide', 'fullscreen'],
};

export default {
  name: 'Modal',

  components: {
    XIcon,
    Button,
    FocusTrap,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    /**
     * Hides the close button in the top right corner if the modal content will define its own
     */
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the ability to close the modal by clicking outside of it
     * Should be set to true for any modal containing a form where accidental dismissal could result in data loss
     */
    disableEasyClose: {
      type: Boolean,
      default: false,
    },
    portalTarget: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'base',
      validator: (val) => ModalProps.sizes.includes(val),
    },
    /**
     * Prevent the content from being wrapped in a vertically scrolling container
     */
    disableScroll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    sizeClass() {
      switch (this.size) {
        default:
        case 'base':
          return 'px-14 py-12 sm:max-w-2xl sm:h-auto';
        case 'small':
          return 'px-10 py-8 sm:max-w-md sm:h-auto';
        case 'wide':
          return 'px-10 py-8 sm:max-w-[1108px] sm:h-auto';
        case 'fullscreen':
          return 'h-full';
      }
    },
  },

  watch: {
    show() {
      this.toggleOpen(this.show);
    },
  },

  beforeMount() {
    this.toggleOpen(this.show);
  },

  beforeDestroy() {
    this.toggleOpen(false);
  },

  methods: {
    escListener(event) {
      if (event.key === 'Escape') {
        this.requestClose();
      }
    },

    scrollTop(top) {
      this.$refs.scrollCont?.scrollTo({ top, behavior: 'instant' });
    },

    toggleOpen(open) {
      if (open && !this.isOpen) {
        this.isOpen = true;
        document.addEventListener('keyup', this.escListener);
        if (modalCount++ === 0) {
          document.documentElement.classList.add('modal-open');
        }
      } else if (!open && this.isOpen) {
        this.isOpen = false;
        document.removeEventListener('keyup', this.escListener);
        if (--modalCount === 0) {
          document.documentElement.classList.remove('modal-open');
        }
      }
    },

    requestEasyClose() {
      if (!this.disableEasyClose && !this.hideCloseButton) {
        this.requestClose();
      }
    },

    requestClose() {
      this.$emit('hide');
    },
  },
};
