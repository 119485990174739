import { registerApp } from 'lib/app-registry';

import azureAdApi from '../../../app-modules/azure_ad_api';
import example from '../../../app-modules/example';
import google from '../../../app-modules/google';
import buildMergeApp from '../../../app-modules/merge/build-merge-app';
import mergeApps from '../../../app-modules/merge/merge-apps';
import msExchange from '../../../app-modules/ms_exchange';
import okta from '../../../app-modules/okta';
import sftp from '../../../app-modules/sftp';
import sharepoint from '../../../app-modules/sharepoint';
import slack from '../../../app-modules/slack';
import teams from '../../../app-modules/teams';
import text from '../../../app-modules/text';
import ukgPro from '../../../app-modules/ukg_pro';
import workvivo from '../../../app-modules/workvivo';

/**
 * Register apps (plugins) for the app library
 */
export default () => {
  registerApp(example);
  registerApp(sharepoint);
  registerApp(slack);
  registerApp(text);
  registerApp(okta);
  registerApp(sftp);
  registerApp(teams);
  registerApp(azureAdApi);
  registerApp(msExchange);
  registerApp(google);
  registerApp(ukgPro);
  registerApp(workvivo);

  mergeApps.forEach(async (appName) => {
    const built = await buildMergeApp(appName);
    registerApp(built);
  });
};
