import BoltIcon from '@workshop/baja/assets/icons/icon-bolt.svg';
import GroupIcon from '@workshop/baja/assets/icons/icon-groups.svg';
import ListIcon from '@workshop/baja/assets/icons/icon-streams-mini.svg';
import SyncIcon from '@workshop/baja/assets/icons/icon-sync.svg';
import UploadIcon from '@workshop/baja/assets/icons/icon-upload.svg';
import UserIcon from '@workshop/baja/assets/icons/icon-user.svg';

import { getListColor, getListLabel } from './list';

export const getOmnisearchColor = (item) => {
  switch (item.model) {
    case 'list':
      return getListColor(item.listType);
    default:
    case 'user':
      return 'blue';
    case 'userGroup':
      return 'purple';
  }
};

export const getOmnisearchLabel = (item) => {
  if (typeof item === 'string') {
    return item;
  }

  switch (item.model) {
    case 'list':
      return getListLabel(item);
    default:
    case 'user':
      if (item.firstName === '_' || item.lastName === '_') {
        return item.smsOnly ? item.phoneNumber : item.email;
      }
      return item.name || item.email;
  }
};

export const getOmnisearchIcon = (item) => {
  switch (item.model) {
    case 'list':
      return ListIcon;
    case 'user':
      return UserIcon;
    case 'userGroup':
      return GroupIcon;
    default:
      return undefined;
  }
};

export const getOmnisearchSourceIcon = (item) => {
  const isList = item.model === 'list';
  if (item.synced || (isList && item.listSource === 'synced')) {
    return SyncIcon;
  }

  if (isList && item.listSource === 'automatic') {
    return BoltIcon;
  }

  if (isList && item.listSource === 'csv_import') {
    return UploadIcon;
  }

  return undefined;
};

export const getOmnisearchSource = (item) => {
  switch (item.model) {
    case 'list':
      return item.listSource;
    case 'user':
      return item.synced ? 'synced' : undefined;
    default:
      return undefined;
  }
};
