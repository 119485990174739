import VueFormulate from '@braid/vue-formulate';
import Vue from 'vue';

import Autocomplete from 'app/formulate/autocomplete/Autocomplete.vue';
import DateInput from 'app/formulate/date-input/DateInput.vue';
import EmailPicker from 'app/formulate/email-picker/EmailPicker.vue';
import FilterSelect from 'app/formulate/filter-select/FilterSelect.vue';
import FormHelp from 'app/formulate/form-help/FormHelp.vue';
import FormLabel from 'app/formulate/form-label/FormLabel.vue';
import Multiselect from 'app/formulate/multiselect/Multiselect.vue';
import OneTimePassword from 'app/formulate/one-time-password/OneTimePassword.vue';
import Password from 'app/formulate/password/Password.vue';
import { formulateUpload } from 'lib/api-helpers/uploader';
import {
  domainMessage,
  domainValidator,
  emailValidator,
  phoneNumberMessage,
  phoneNumberValidator,
  requireOneMessage,
  requireOneNumberMessage,
  requireOneNumberValidator,
  requireOneSymbolMessage,
  requireOneSymbolValidator,
  requireOneValidator,
} from 'utils/validation/custom-validators';
import errorHandler from 'utils/validation/error-handler';

export default () => {
  // Using ActiveStorage DirectUpload here to upload files back to rails.
  // https://edgeguides.rubyonrails.org/active_storage_overview.html#integrating-with-libraries-or-frameworks
  Vue.use(VueFormulate, {
    classes: {
      // add class to allow styling of disabled labels on box inputs (checkbox, radio)
      wrapper: (ctx, classes) =>
        classes.concat(ctx.attrs.disabled ? ['is-disabled'] : []),
    },
    errorHandler,
    uploader: formulateUpload,
    // global custom validators
    rules: {
      domain: domainValidator,
      email: emailValidator,
      phoneNumber: phoneNumberValidator,
      requireOne: requireOneValidator,
      oneNumber: requireOneNumberValidator,
      oneSymbol: requireOneSymbolValidator,
    },
    locales: {
      en: {
        domain: domainMessage,
        phoneNumber: phoneNumberMessage,
        requireOne: requireOneMessage,
        oneNumber: requireOneNumberMessage,
        oneSymbol: requireOneSymbolMessage,
      },
    },
    slotComponents: {
      help: FormHelp,
      label: FormLabel,
    },
    slotProps: {
      help: ['helpIcon'],
      label: ['tooltip', 'tooltipWidth', 'showOptional'],
      errorList: ['errorTooltip', 'errorTooltipWidth'],
    },
    library: {
      multiselect: {
        classification: 'text',
        component: Multiselect,
        slotProps: {
          component: [
            'badgeColorFunction',
            'badgeIconFunction',
            'badgeIconSizeFunction',
            'defaultExpand',
            'disableSynced',
            'editing',
            'interactiveItems',
            'isDisabledFunction',
            'mapLabelFunction',
            'mapValueFunction',
            'searchEndpoint',
            'searchEndpointInfinite',
            'searchFunction',
            'searchParams',
            'searchQuery',
            'searchVerb',
            'showWarningFunction',
            'syncLocked',
            'variant',
            'behavior',
          ],
        },
      },
      filterSelect: {
        classification: 'select',
        component: FilterSelect,
        slotProps: {
          component: [
            'allowFiltering',
            'variant',
            'mapValueFunction',
            'mapLabelFunction',
            'mapFilterFunction',
            'maxWidth',
          ],
        },
      },
      autocomplete: {
        classification: 'text',
        component: Autocomplete,
        slotProps: {
          component: [
            'behavior',
            'disableSynced',
            'isDisabledFunction',
            'mapLabelFunction',
            'mapValueFunction',
            'searchEndpoint',
            'searchEndpointInfinite',
            'searchFunction',
            'searchParams',
            'searchQuery',
            'searchVerb',
            'variant',
          ],
        },
      },
      dateinput: {
        classification: 'text',
        component: DateInput,
        slotProps: {
          component: [
            'dateFormat',
            'enableTime',
            'maxDate',
            'minDate',
            'minuteIncrement',
            'mode',
            'static',
            'useCompanyTimeZone',
            'variant',
          ],
        },
      },
      emailPicker: {
        classification: 'text',
        component: EmailPicker,
        slotProps: {
          component: ['domains', 'splitOutput'],
        },
      },
      oneTimePassword: {
        classification: 'text',
        component: OneTimePassword,
        slotProps: {
          component: ['inputsNumber', 'inputType'],
        },
      },
      passwordInput: {
        classification: 'text',
        component: Password,
      },
    },
  });
};
