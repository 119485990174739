<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <div v-if="exchange.status === 'pending_activation'">
      <Title :level="3">Microsoft Exchange distribution group syncing</Title>
      <p>
        To begin, please authorize a user with an active Exchange Mailbox.
        Workshop uses
        <Link
          href="https://docs.microsoft.com/en-us/exchange/client-developer/exchange-web-services/start-using-web-services-in-exchange"
          :external="true"
        >
          Exchange Web Services
        </Link>
        to expand distribution groups. Workshop does not require Exchange
        administrator privileges to accomplish this task and we recommend that
        you utilize an unprivileged service account for this connection.
      </p>
      <Button
        class="mt-9 w-full md:w-auto"
        data-testid="generate-api-key-button"
        @click="initiateOAuth"
      >
        Authorize Exchange user
      </Button>
    </div>

    <div v-if="exchange.status === 'active'">
      <Title :level="3">Microsoft Exchange distribution group syncing</Title>

      <UrgentAlert
        severity="success"
        message="Connected to MS Exchange!"
        :dismissible="false"
        cache-key="ms-exchange-connected"
        header=""
        class="my-6"
      />

      <div v-if="exchange.distributionGroups.length > 0">
        <Title :level="4">Actively synced groups</Title>

        <Table :shadow="false" variant="tertiary" gradient-color="white">
          <template #head>
            <TableHead
              :headers="[
                { title: 'Group name' },
                { title: 'Email address' },
                { title: '' },
              ]"
              :menu="false"
              :select="false"
            />
          </template>
          <TableRow
            v-for="group in exchange.distributionGroups"
            :key="group.id"
            :hover="false"
          >
            <TableData :full="true">
              <span class="truncate font-medium">
                {{ group.name }}
              </span>
            </TableData>
            <TableData>
              {{ group.emailAddress }}
            </TableData>
            <TableData>
              <Button
                size="xs"
                color="gray"
                variant="tertiary"
                aria-label="Remove this distribution list"
                @click="showDeleteConfirm = true"
                ><TrashIcon class="w-3.5 text-red-500"
              /></Button>

              <ConfirmationModal
                v-if="currentUser.fullAccess"
                :text="`You are about to remove the group ${group.name}, and it will no longer be synced.`"
                :show="showDeleteConfirm"
                :is-loading="isDeleting"
                @hide="showDeleteConfirm = false"
                @confirm="removeDistributionGroup(group.id)"
              />
            </TableData>
          </TableRow>
        </Table>
      </div>

      <div v-else class="mt-4">
        <Title :level="4">Add a dynamic distribution group</Title>
        <p class="mb-2 font-normal text-gray-500">
          Enter the email address associated with the group you would like to
          sync.
        </p>
      </div>

      <FormulateForm
        v-slot="{ isLoading }"
        name="addDistributionGroup"
        class="flex w-full items-start gap-2"
        @submit="addDistributionGroup"
      >
        <FormulateInput
          type="email"
          name="email"
          placeholder="everyone@company.com"
          validation="required|email"
          class="!mb-0 w-full"
        />
        <Button type="submit" :is-loading="isLoading" class="shrink-0 !py-2.5">
          Add new distribution group
        </Button>
      </FormulateForm>

      <div class="mt-10">
        <Title :level="4">Link a different exchange user</Title>
        <p class="font-normal text-gray-500">
          Currently connected with account
          {{ exchange.adminEmail }}
        </p>
        <Button
          class="mt-2 w-full md:w-auto"
          data-testid="generate-api-key-button"
          variant="secondary"
          color="danger"
          @click="initiateOAuth"
        >
          Unlink
        </Button>
      </div>
    </div>
  </div>

  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import TrashIcon from '@workshop/baja/assets/icons/icon-trash-can.svg';
import Button from 'app/core/button/Button.vue';
import Link from 'app/core/link/Link.vue';
import ConfirmationModal from 'app/core/modal/confirmation-modal/ConfirmationModal.vue';
import Table from 'app/core/table/Table.vue';
import TableData from 'app/core/table-data/TableData.vue';
import TableHead from 'app/core/table-head/TableHead.vue';
import TableRow from 'app/core/table-row/TableRow.vue';
import Title from 'app/core/title/Title.vue';
import UrgentAlert from 'app/core/urgent-alert/UrgentAlert.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';
import {
  msExchangeDistributionGroupPath,
  msExchangeDistributionGroupsUrl,
  userExchangeEwsOmniauthAuthorizeUrl,
} from 'routes';

export default {
  name: 'ManageMsExchange',

  components: {
    AppPlaceholder,
    Button,
    ConfirmationModal,
    Link,
    LoadingIcon,
    Table,
    TableData,
    TableHead,
    TableRow,
    Title,
    TrashIcon,
    UrgentAlert,
  },

  data() {
    return {
      isDeleting: false,
      showDeleteConfirm: false,
    };
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      exchange: 'connectedData',
    }),
  },

  created() {
    this.getConnected({ type: this.app.type });

    if (this.successParam()) {
      this.$toast('Your ActiveDirectory has been successfully linked!', {
        type: 'success',
      });
    }
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },

    async initiateOAuth() {
      window.location = userExchangeEwsOmniauthAuthorizeUrl({
        exchangeIntegrationId: this.exchange.id,
      });
    },

    async addDistributionGroup(data) {
      try {
        await axios.post(
          msExchangeDistributionGroupsUrl(this.exchange.id, data)
        );
        this.getConnected({ type: this.app.type });
      } catch (err) {
        if (err.response?.status === 422) {
          this.$formulate.handle(err, 'addDistributionGroup');
        }
      }
      // add a finally
    },

    async removeDistributionGroup(groupId) {
      try {
        const resp = await axios.delete(
          msExchangeDistributionGroupPath(this.exchange.id, groupId)
        );

        if (resp.status === 200) {
          this.$toast(
            'This group will be removed on the next successful directory sync',
            {
              type: 'success',
            }
          );
        }
      } catch (err) {
        this.$toast('There was a problem removing the distribution group.', {
          type: 'error',
        });
      } finally {
        this.showDeleteConfirm = false;
      }
    },

    successParam() {
      return !!new URLSearchParams(window.location.search).get('success');
    },
  },
};
</script>
