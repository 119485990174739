<template>
  <div>
    <label for="search" class="sr-only">
      {{ label }}
    </label>
    <div
      :class="[
        `search-wrapper search-wrapper--${variant}`,
        { 'search-wrapper--has-focus': hasFocus },
      ]"
    >
      <SearchIcon class="search-wrapper__icon" />
      <input
        ref="searchInput"
        autocomplete="off"
        type="search"
        :placeholder="label"
        :disabled="disabled"
        :value="query"
        :name="name"
        maxlength="150"
        v-on="filteredListeners"
        @input="handleInput"
        @click.stop="
          /* stop event propagation for clicks to prevent any ancestor from triggering unintended actions (i.e. expand/collapse)  */
        "
        @keyup.space.prevent.stop="
          /* stop event propagation and prevent default to prevent 'space' key from being captured by any ancestor from triggering unintended actions (i.e. expand/collapse) */
        "
        @keypress.enter="handleKeypressEnter"
        @focus="hasFocus = true"
        @blur="hasFocus = false"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';

import SearchIcon from '@workshop/baja/assets/icons/icon-search.svg';

export const SearchInputProps = {
  variants: ['primary', 'secondary', 'plain'],
};

export default {
  name: 'SearchInput',

  components: {
    SearchIcon,
  },

  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Search',
    },
    name: {
      type: String,
      default: 'search',
    },
    value: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (val) => SearchInputProps.variants.includes(val),
    },
  },

  data() {
    return {
      query: '',
      debouncedSearch: debounce(this.performSearch, 500),
      focusTimeout: null,
      hasFocus: false,
    };
  },

  computed: {
    filteredListeners() {
      const { search, input, ...rest } = this.$listeners;
      return rest;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.query = v;
      },
    },
  },

  mounted() {
    if (this.autofocus) {
      // allow time for animations to complete
      this.focusTimeout = setTimeout(() => {
        this.$refs.searchInput.focus();
        this.focusTimeout = null;
      }, 300);
    }
  },

  beforeDestroy() {
    if (this.focusTimeout) {
      clearTimeout(this.focusTimeout);
    }
    this.debouncedSearch.cancel();
  },

  methods: {
    performSearch() {
      this.$emit('search', this.query);
    },

    handleInput(event) {
      this.query = event.target.value;
      this.debouncedSearch();
    },

    handleKeypressEnter() {
      this.debouncedSearch.flush();
    },
  },
};
</script>
