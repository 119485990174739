<template>
  <Modal :size="size" :show="show" v-on="$listeners">
    <div class="text-center md:text-left">
      <Title :level="2" :size="3" class="mb-4">
        <template #icon>
          <IconWrap color="warning">
            <WarnIcon />
          </IconWrap>
        </template>

        {{ heading }}
      </Title>
      <p class="mb-10 block">
        {{ confirmText }}
      </p>

      <div class="flex md:justify-end">
        <Button
          class="w-full md:w-auto"
          variant="secondary"
          color="gray"
          :disabled="isLoading"
          @click="handleCancel"
          >Cancel</Button
        >
        <Button
          class="ml-4 w-full md:w-auto"
          color="danger"
          :is-loading="isLoading"
          data-testid="delete-button"
          @click="handleConfirm"
          >{{ confirmLabel }}</Button
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import WarnIcon from '@workshop/baja/assets/icons/icon-warn.svg';
import Button from 'app/core/button/Button.vue';
import IconWrap from 'app/core/icon-wrap/IconWrap.vue';
import Modal, { ModalProps } from 'app/core/modal/Modal.vue';
import Title from 'app/core/title/Title.vue';

export default {
  name: 'ConfirmationModal',

  components: {
    Button,
    IconWrap,
    Modal,
    Title,
    WarnIcon,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
      validator: (value) => ModalProps.sizes.includes(value),
    },
    listLength: {
      type: Number,
      default: 0,
    },
    heading: {
      type: String,
      default: 'Are you sure?',
    },
    text: {
      type: String,
      required: false,
      default: undefined,
    },
    model: {
      type: String,
      required: false,
      default: '',
    },
    confirmLabel: {
      type: String,
      default: 'Delete',
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    confirmText() {
      let confirmText = '';

      if (this.text) {
        confirmText = this.text;
      } else if (this.listLength && this.model) {
        confirmText = `You are about to delete ${this.listLength} ${
          this.model
        }${this.listLength > 1 ? 's' : ''}. This action cannot be undone.`;
      } else {
        confirmText = 'This action cannot be undone.';
      }
      return confirmText;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('hide');
    },
    handleConfirm() {
      this.$emit('confirm');
    },
  },
};
</script>
