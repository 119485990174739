import CheckmarkIcon from '@workshop/baja/assets/icons/icon-checkmark-full.svg';
import InfoIcon from '@workshop/baja/assets/icons/icon-info.svg';
import WarnIcon from '@workshop/baja/assets/icons/icon-warn.svg';
import ErrorIcon from '@workshop/baja/assets/icons/icon-x-full.svg';
import XIcon from '@workshop/baja/assets/icons/icon-x.svg';
import Link from 'app/core/link/Link.vue';

export const UrgentAlertProps = {
  severity: ['error', 'warning', 'info', 'success'],
};

export const UrgentAlertColors = {
  error: {
    colors: 'bg-red-50 text-red-700',
    icon: 'ErrorIcon',
  },
  warning: {
    colors: 'bg-yellow-50 text-yellow-900',
    icon: 'WarnIcon',
  },
  info: {
    colors: 'bg-blue-50 text-blue-500',
    icon: 'InfoIcon',
  },
  success: {
    colors: 'bg-green-50 text-green-500',
    icon: 'CheckmarkIcon',
  },
};

export default {
  name: 'UrgentAlert',

  components: {
    CheckmarkIcon,
    ErrorIcon,
    InfoIcon,
    Link,
    WarnIcon,
    XIcon,
  },

  props: {
    dismissible: {
      type: Boolean,
      default: true,
    },
    header: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    /** Set to true when you need a click handler on the alert link */
    linkButton: {
      type: Boolean,
      default: false,
    },
    /** Link text override */
    linkText: {
      type: String,
      default: 'Learn more',
    },
    /** Use when you need a static external url for the alert link */
    linkUrl: {
      type: String,
      default: undefined,
    },
    cacheKey: {
      type: String,
      required: true,
    },
    severity: {
      type: String,
      default: 'warning',
      validator: (val) => UrgentAlertProps.severity.includes(val),
    },
  },

  data() {
    return {
      dismissed: false,
      UrgentAlertColors,
    };
  },

  beforeMount() {
    this.dismissed = !!localStorage.getItem(this.cacheKey);
  },

  computed: {
    getIcon() {
      return UrgentAlertColors[this.severity].icon;
    },
  },

  methods: {
    handleDismiss() {
      this.dismissed = true;
      localStorage.setItem(this.cacheKey, true);
    },

    handleLinkButtonClick() {
      this.$emit('link-clicked');
    },
  },
};
