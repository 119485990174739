export default {
  name: 'PreviewControlsWorkvivo',

  props: {
    details: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleInput(detailsPropName, value) {
      if (value !== false) {
        this.$emit('preview-control-change', detailsPropName, value);
      }
    },
  },
};
