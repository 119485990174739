<template>
  <div>
    <slot :width="width" :height="height"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: null,
      height: null,
      observer: null,
    };
  },
  mounted() {
    this.observer = new ResizeObserver(([entry]) => {
      const { width, height } = entry.contentRect;
      this.width = width;
      this.height = height;
    });
    this.observer.observe(this.$el);
  },

  destroyed() {
    this.observer.unobserve(this.$el);
  },
};
</script>
