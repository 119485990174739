/**
 * Adds scroll listeners and info.
 */
export default ({
  handleScrollInfoMethodName,
  infinitePlacerRefName,
  scrollerRefName,
}) => ({
  data() {
    return {
      scrollMixinIsMounted: false,
      scrollRef: undefined,
    };
  },

  mounted() {
    this.scrollMixinIsMounted = true;
    this.addScrollListener();
  },

  methods: {
    addScrollListener() {
      this.$nextTick(() => {
        if (this.scrollMixinIsMounted && this.$refs[scrollerRefName]) {
          this.scrollRef = this.$refs[scrollerRefName];
          this.scrollRef.addEventListener('scroll', this.handleScroll);
        }
      });
    },
    handleScroll() {
      const { offsetHeight, scrollHeight, scrollTop } = this.scrollRef;
      const scrollBottom = scrollTop + offsetHeight;
      const totalHeight = scrollHeight;
      const visibleHeight = offsetHeight;
      const scrollPercY =
        (Math.ceil(scrollBottom) - visibleHeight) /
        (totalHeight - visibleHeight);
      const infinitePlacerRef = this.$refs[infinitePlacerRefName];
      const infiniteYFromVisible = infinitePlacerRef
        ? infinitePlacerRef.offsetTop - scrollBottom
        : 0;

      if (totalHeight) {
        this[handleScrollInfoMethodName]({
          infiniteYFromVisible,
          scrollBottom,
          scrollPercY,
          scrollTop,
          totalHeight,
          visibleHeight,
        });
      }
    },
    removeScrollListener() {
      if (this.scrollRef) {
        this.scrollRef.removeEventListener('scroll', this.handleScroll);
      }
    },
  },

  beforeDestroy() {
    this.scrollMixinIsMounted = false;
    this.removeScrollListener();
  },
});
