import InfoOutlineIcon from '@workshop/baja/assets/icons/icon-info-outline.svg';

export default {
  name: 'FormHelp',

  props: {
    context: {
      type: Object,
      required: true,
    },
    /** Whether to hide the help icon (default, false), show the default icon (true), or pass your own icon component. */
    helpIcon: {
      type: [Boolean, Object],
      default: false,
    },
  },

  computed: {
    helpIconComp() {
      if (!this.helpIcon) {
        return null;
      }

      return this.helpIcon === true ? InfoOutlineIcon : this.helpIcon;
    },
  },
};
