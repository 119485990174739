<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive" class="bg-gray-50 p-6">
    <Title :level="3" :size="4">Your API Key</Title>
    <div class="mt-2 break-all text-xl">{{ example.apiKey }}</div>
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Title from 'app/core/title/Title.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';

export default {
  name: 'ManageExample',

  components: {
    AppPlaceholder,
    LoadingIcon,
    Title,
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      example: 'connectedData',
    }),
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },
  },
};
</script>
