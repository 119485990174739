import { workvivoIndexPath, workvivoPath } from 'routes';

import ConfigureWorkvivo from './configure-workvivo/ConfigureWorkvivo.vue';
import ManageWorkvivo from './manage-workvivo/ManageWorkvivo.vue';
import PreviewControlsWorkvivo from './preview-workvivo/PreviewControlsWorkvivo.vue';
import PreviewWorkvivo from './preview-workvivo/PreviewWorkvivo.vue';

export default {
  name: 'workvivo',
  label: 'Workvivo',
  type: 'delivery',
  manageComponent: ManageWorkvivo,
  previewComponent: PreviewWorkvivo,
  previewControlsComponent: PreviewControlsWorkvivo,
  indexPath: workvivoIndexPath,
  path: workvivoPath,
  allowFeaturedImage: true,
  featuredImageHelpText:
    'Recommended: 800px x 330px. Safe space for text and important elements of an image: 490px x 330px (centered).',
  allowCustomMessage: false,
  configureComponent: ConfigureWorkvivo,
  customMessageMaxLength: 320,
  hexColor: '#0056D6',
};
