export const IconWrapProps = {
  sizes: ['sm', 'md', 'lg', 'xl'],
  iconSizes: ['xs', 'sm', 'md', 'lg', 'xl'],
  colors: [
    'disabled',
    'intermediary',
    'positive',
    'primary',
    'secondary',
    'warning',
  ],
};

export default {
  name: 'Icon',

  props: {
    color: {
      type: String,
      required: false,
      default: undefined,
      validator: (val) => IconWrapProps.colors.includes(val),
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: (val) => IconWrapProps.sizes.includes(val),
    },
    iconSize: {
      type: String,
      required: false,
      default: 'md',
      validator: (val) => IconWrapProps.iconSizes.includes(val),
    },
    iconClass: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  computed: {
    colorClass() {
      switch (this.color) {
        case 'warning':
          return 'bg-red-50 text-red-500';
        case 'disabled':
          return 'bg-gray-200 text-gray-500';
        case 'positive':
          return 'bg-green-50 text-green-500';
        case 'intermediary':
          return 'bg-yellow-50 text-yellow-500';
        case 'secondary':
          return 'bg-purple-50 text-purple-600';
        default:
        case 'primary':
          return 'bg-blue-50 text-blue-500';
      }
    },
    sizeClass() {
      switch (this.size) {
        case 'xl':
          return 'w-20 h-20';
        case 'lg':
          return 'w-10 h-10 md:w-[3.125rem] md:h-[3.125rem]';
        case 'sm':
          return 'w-8 h-8';
        default:
        case 'md':
          return 'w-10 h-10';
      }
    },
    iconSizeClass() {
      const classes = [];
      switch (this.iconSize) {
        case 'xs':
          classes.push('w-3 h-3');
          break;
        case 'sm':
          classes.push('w-4 h-4');
          break;
        case 'lg':
          classes.push('w-6 h-6');
          break;
        case 'xl':
          classes.push('w-9 h-9');
          break;
        default:
        case 'md':
          classes.push('w-5 h-5');
          break;
      }

      if (this.size === 'lg') {
        classes.push('md:w-7 md:h-7');
      }

      return classes.join(' ');
    },
  },
};
