import HiddenIcon from '@workshop/baja/assets/icons/icon-eye-hidden.svg';
import VisibleIcon from '@workshop/baja/assets/icons/icon-eye-visible.svg';

export default {
  name: 'Password',
  components: {
    VisibleIcon,
    HiddenIcon,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
