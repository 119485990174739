import { render, staticRenderFns } from "./SortHeader.vue?vue&type=template&id=6e44de66&scoped=true"
import script from "./SortHeader.vue?vue&type=script&lang=js"
export * from "./SortHeader.vue?vue&type=script&lang=js"
import style0 from "./SortHeader.scss?vue&type=style&index=0&id=6e44de66&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../common/temp/legacy/node_modules/.pnpm/vue-loader@15.11.1_css-loader@5.2.7_prettier@3.3.3_vue-template-compiler@2.6.14_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e44de66",
  null
  
)

export default component.exports