import { msExchangeIndexPath, msExchangePath } from 'routes';

import ManageMsExchange from './manage-ms-exchange/ManageMsExchange.vue';

export default {
  name: 'ms_exchange',
  label: 'Microsoft Exchange',
  type: 'data',
  manageComponent: ManageMsExchange,
  indexPath: msExchangeIndexPath,
  path: msExchangePath,
};
