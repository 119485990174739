import BoltIcon from '@workshop/baja/assets/icons/icon-bolt.svg';
import ExclamationWarnIcon from '@workshop/baja/assets/icons/icon-exclamation-warn.svg';
import SyncIcon from '@workshop/baja/assets/icons/icon-sync.svg';
import UploadIcon from '@workshop/baja/assets/icons/icon-upload.svg';
import Tooltip from 'app/core/tooltip/Tooltip.vue';

import BadgeRemoveButton from './badge-remove-button/BadgeRemoveButton.vue';

export const BadgeProps = {
  colors: ['gray', 'gray-dark', 'green', 'yellow', 'purple', 'red', 'blue'],
  sizes: ['md', 'lg'],
  sources: ['static', 'synced', 'automatic', 'csv_import'],
};

export default {
  name: 'Badge',

  components: {
    BadgeRemoveButton,
    ExclamationWarnIcon,
    Tooltip,
  },

  props: {
    color: {
      type: String,
      default: 'blue',
      validator: (val) => BadgeProps.colors.includes(val),
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
    external: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: undefined,
      required: false,
    },
    icon: {
      type: Object,
      default: undefined,
      required: false,
    },
    iconSizeClass: {
      type: String,
      default: 'w-2.5',
    },
    interactive: {
      type: Boolean,
      default: false,
    },
    showRemoveButton: {
      type: Boolean,
      default: false,
    },
    showWarnIcon: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: null,
      validator: (val) => !val || BadgeProps.sources.includes(val),
    },
    size: {
      type: String,
      default: 'md',
      validator: (val) => BadgeProps.sizes.includes(val),
    },
    locked: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: 'item',
    },
    tag: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    colorClasses() {
      switch (this.color) {
        case 'purple':
          return 'text-purple-500 bg-purple-50';
        case 'green':
          return 'text-green-500 bg-green-50';
        case 'yellow':
          return 'text-yellow-900 bg-yellow-50';
        case 'red':
          return 'text-red-700 bg-red-50';
        case 'gray':
          return 'text-gray-500 bg-gray-100';
        case 'gray-dark':
          return 'text-gray-700 bg-gray-200';
        default:
          return 'text-blue-500 bg-blue-50';
      }
    },

    sizeClasses() {
      switch (this.size) {
        case 'lg':
          return 'text-base';
        default:
          return 'text-sm';
      }
    },

    sourceIconClasses() {
      switch (this.source) {
        case 'synced':
          return 'h-2.5';
        case 'csv_import':
        case 'automatic':
          return 'h-3';
        default:
          return '';
      }
    },

    tagOrDefault() {
      const defaultTag = this.href ? 'a' : 'div';
      return this.tag || defaultTag;
    },

    sourceIcon() {
      switch (this.source) {
        case 'synced':
          return SyncIcon;
        case 'automatic':
          return BoltIcon;
        case 'csv_import':
          return UploadIcon;
        default:
          return null;
      }
    },
  },

  methods: {
    handleClick() {
      if (this.interactive && !this.disabled) {
        this.$emit('click');
      }
    },
    handleRemoveClick() {
      this.$emit('remove');
    },
  },
};
