import { isNumber } from 'lodash-es';

import formatNumber from 'utils/numbers/format-number';

export const LIST_TYPES = Object.freeze({
  department: 'Department',
  team: 'Team',
  location: 'Location',
  standard: 'Standard',
});

export const LIST_SOURCES = Object.freeze({
  automatic: 'automatic',
  static: 'static',
  synced: 'synced',
  csv: 'csv_import',
});

export const getListColor = (listType) => {
  switch (listType) {
    case 'location':
      return 'purple';
    case 'department':
      return 'blue';
    case 'team':
      return 'green';
    case 'deleted':
      return 'gray-dark';
    default:
      return 'yellow';
  }
};

export const getIconWrapColor = (listType) => {
  switch (listType) {
    case 'location':
      return 'secondary';
    case 'department':
      return 'primary';
    case 'team':
      return 'positive';
    default:
      return 'intermediary';
  }
};

export const getListLabel = (list) => {
  if (!isNumber(list.listMembershipsCount)) {
    return list.title;
  }
  return `${list.title} (${formatNumber(list.listMembershipsCount)})`;
};
