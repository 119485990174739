import EmailIcon from '@workshop/baja/assets/icons/icon-email.svg';
import Smiley1 from '@workshop/baja/assets/icons/icon-smiley1.svg';
import Smiley0 from '@workshop/baja/assets/icons/icon-smiley10.svg';
import Smiley2 from '@workshop/baja/assets/icons/icon-smiley2.svg';
import Smiley3 from '@workshop/baja/assets/icons/icon-smiley3.svg';
import Smiley4 from '@workshop/baja/assets/icons/icon-smiley4.svg';
import Smiley5 from '@workshop/baja/assets/icons/icon-smiley5.svg';
import Smiley6 from '@workshop/baja/assets/icons/icon-smiley6.svg';
import Smiley7 from '@workshop/baja/assets/icons/icon-smiley7.svg';
import Smiley8 from '@workshop/baja/assets/icons/icon-smiley8.svg';
import Smiley9 from '@workshop/baja/assets/icons/icon-smiley9.svg';
import TextIcon from '@workshop/baja/assets/icons/icon-text.svg';
import UserIcon from '@workshop/baja/assets/icons/icon-user.svg';
import XIcon from '@workshop/baja/assets/icons/icon-x-outline.svg';

export const AvatarProps = {
  sizes: ['xs', 'sm', 'md', 'lg', 'xl', '2xl'],
};

export const AvatarBgColors = ['green', 'blue', 'purple', 'orange'];

export const SmileyBgColors = ['yellow', 'blue', 'orange', 'purple', 'pink'];

export default {
  name: 'Avatar',
  components: {
    EmailIcon,
    UserIcon,
    XIcon,
    Smiley0,
    Smiley1,
    Smiley2,
    Smiley3,
    Smiley4,
    Smiley5,
    Smiley6,
    Smiley7,
    Smiley8,
    Smiley9,
    TextIcon,
  },

  props: {
    person: {
      type: Object,
      required: true,
    },
    discarded: {
      type: Boolean,
      default: undefined,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
      validator: (val) => AvatarProps.sizes.includes(val),
    },
    hovered: {
      type: Boolean,
      default: undefined,
    },
  },

  data() {
    return {
      deterministicValues: {
        staticBg: 0,
        gradientBg: 0,
        smiley: 0,
      },
    };
  },

  mounted() {
    this.setDeterministicValues();
  },

  computed: {
    hasFullName() {
      return this.person.firstName !== '_' && this.person.lastName !== '_';
    },
  },

  methods: {
    prefixClass(value) {
      return value ? `avatar--${value}` : undefined;
    },

    getDeterministicValue(total) {
      const idDigits = this.person.id?.match(/\d+/) || 0;
      return parseInt(idDigits % total, 10);
    },

    setDeterministicValues() {
      this.deterministicValues = {
        staticBg: this.getDeterministicValue(4),
        gradientBg: this.getDeterministicValue(5),
        smiley: this.getDeterministicValue(10),
      };
    },

    getBgColorClass() {
      if (!this.person.role) {
        return `avatar--${AvatarBgColors[this.deterministicValues.staticBg]}`;
      }
      return undefined;
    },

    getInitial(person) {
      return person.name ? person.name[0].toUpperCase() : '+';
    },

    getSmileyClass() {
      if (this.person.role && !this.person.avatarTag) {
        return `smiley--${this.deterministicValues.smiley} smiley--${
          SmileyBgColors[this.deterministicValues.gradientBg]
        }`;
      }
      return undefined;
    },

    getSmiley() {
      return `Smiley${this.deterministicValues.smiley}`;
    },
  },
};
