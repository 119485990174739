var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"filterSelect"},[_c('Popper',{class:_vm.wrapperClass,attrs:{"show":_vm.showDropdown,"portal-target":_vm.portalTarget,"disabled":_vm.context.attributes.disabled,"full-width":true,"max-width":_vm.maxWidth,"offset-distance":10,"tag-name":"div"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('ul',{ref:"filterSelectList",staticClass:"filter-select-list"},[(_vm.allowFiltering)?_c('SearchInput',{ref:"searchInput",staticClass:"filter-select-search",attrs:{"variant":"plain","value":_vm.query},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnterPress.apply(null, arguments)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.increment.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.decrement.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDropdown.apply(null, arguments)}],"search":_vm.handleInputChange}}):_vm._e(),_vm._v(" "),(_vm.filteredOptions.length)?_vm._l((_vm.filteredOptions),function(option,index){return _c('li',{key:index,class:[
              'filter-select-list-item',
              {
                'filter-select-list-item--selected':
                  _vm.selection && _vm.selection.id === option.id,
              } ],on:{"mousemove":function($event){_vm.selectedIndex = index},"click":function($event){return _vm.handleItemClick(option)}}},[_c('FormulateSlot',{attrs:{"name":"option","context":_vm.context,"option":option}},[_c('FilterSelectOption',{attrs:{"option":option}},[_vm._v("\n                "+_vm._s(_vm.mapLabelFunction(option))+"\n              ")])],1)],1)}):[_c('li',{staticClass:"filter-select-list-item filter-select-list-item--empty"},[_c('p',[_vm._v("No results found.")])])]],2)]},proxy:true}])},[_c('div',{class:[
        ("formulate-input-element formulate-input-element--" + (_vm.context.classification)),
        { active: _vm.dropdownActive } ],attrs:{"data-type":_vm.context.type}},[_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"filterSelectInput",class:_vm.inputClass,attrs:{"autocomplete":"off","readonly":"","type":"text"},domProps:{"value":(_vm.inputValue)},on:{"blur":_vm.context.blurHandler,"focus":_vm.handleInputFocus,"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},_vm.debounceInputChange],"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnterPress.apply(null, arguments)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.increment.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.decrement.apply(null, arguments)}]}},'input',_vm.context.attributes,false))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }