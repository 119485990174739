<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <div v-if="merge.status === 'active'">
      <ContentPlaceholder class="my-10">
        <template #title>
          <div class="flex flex-col items-center">
            <CheckmarkIcon class="mb-4 w-5 text-blue-500" />
            {{ app.name }} is connected!
          </div>
        </template>
      </ContentPlaceholder>
    </div>
    <div v-else>
      <Trebuchet feature-enabled="merge">
        <ContentPlaceholder class="my-10">
          <template #title>Authorize {{ app.name }}</template>
          <template #message
            >Workshop needs to authorize your {{ app.name }} account in order to
            fetch all of your employees.</template
          >
          <template #cta>
            <Button
              variant="primary"
              :disabled="isLoading || mergeSdkLoading"
              :is-loading="mergeSdkLoading"
              @click="fetchMergeLinkToken"
            >
              Authorize {{ app.name }}
            </Button>
          </template>
        </ContentPlaceholder>
      </Trebuchet>

      <Trebuchet feature-disabled="merge">
        <ContentPlaceholder class="my-10">
          <template #title>Complete {{ app.name }} setup</template>
          <template #message
            >Workshop needs some more information to complete the
            {{ app.name }} setup. Reach out to us so we can get those steps
            taken care of!</template
          >
          <template #cta>
            <Button
              variant="primary"
              :disabled="isLoading"
              href="mailto:help@useworkshop.com"
            >
              Contact Workshop
            </Button>
          </template>
        </ContentPlaceholder>
      </Trebuchet>
    </div>
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

import CheckmarkIcon from '@workshop/baja/assets/icons/icon-checkmark.svg';
import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Button from 'app/core/button/Button.vue';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';
import Trebuchet from 'app/utilities/trebuchet/Trebuchet.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';
import { getApp } from 'lib/app-registry';
import loadExternalScript from 'lib/document-helpers/load-external-script';
import { appsMergeLinkTokenPath } from 'routes';

export default {
  name: 'ManageExample',

  components: {
    AppPlaceholder,
    LoadingIcon,
    Button,
    ContentPlaceholder,
    Trebuchet,
    CheckmarkIcon,
  },

  data() {
    return {
      mergeScriptLoaded: null,
      mergeSdkLoading: false,
    };
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      merge: 'connectedData',
    }),
  },

  created() {
    this.getConnected({ type: this.app.type });
    this.mergeScriptLoaded = loadExternalScript(
      'https://cdn.merge.dev/initialize.js'
    );
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },

    async fetchMergeLinkToken() {
      this.mergeSdkLoading = true;
      await this.mergeScriptLoaded;

      const response = await axios.post(appsMergeLinkTokenPath(), {
        integration: this.app.type,
      });
      const { linkToken } = response.data;

      window.MergeLink.initialize({
        linkToken,
        onSuccess: this.onSuccess,
        onReady: () => {
          this.mergeSdkLoading = false;
        },
      });

      window.MergeLink.openLink();
    },

    async onSuccess(token) {
      const payload = {
        app: {
          accountToken: token,
          status: 'active',
        },
      };
      try {
        const response = await axios.patch(
          getApp(this.app.type).path(this.app.id),
          payload
        );
        if (response?.status === 200) {
          this.getConnected({ type: this.app.type });
          this.$toast(`${this.app.name} has been successfully integrated!`, {
            type: 'success',
          });
        } else {
          this.$toast(`Unable to connect with ${this.app.type}`, {
            type: 'error',
          });
          Sentry.captureMessage(
            `Could not connect app ${this.app.name}, status code ${response?.status}`
          );
        }
      } catch (e) {
        this.$toast(`Unable to connect with ${this.app.type}`, {
          type: 'error',
        });
        Sentry.captureException(e);
      }
    },
  },
};
</script>
