import MessageTailImage from 'assets/images/icons/composer/message-tail.svg';
import PhoneBottomImage from 'assets/images/icons/composer/phone-bottom.svg';
import PhoneTopImage from 'assets/images/icons/composer/phone-top.svg';
import PreviewMixin from 'mixins/preview-mixin';

export default {
  name: 'PreviewSms',

  mixins: [PreviewMixin],

  components: {
    PhoneTopImage,
    PhoneBottomImage,
    MessageTailImage,
  },
};
