<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <FeatureGate feature-enabled="sftp_syncing" feature-type="platform">
      <Title :level="3">Set Up SFTP Syncing</Title>
      <p class="mb-4 mt-2">
        To get started, add the following information to your SFTP enabled
        provider's export information.
      </p>
      <FormulateForm>
        <FormulateInput
          :readonly="true"
          label="SFTP URL"
          type="text"
          :value="sftp.url"
        />
        <FormulateInput
          :readonly="true"
          label="SFTP Port"
          type="text"
          value="22"
        />
        <FormulateInput
          :readonly="true"
          label="Username"
          type="text"
          :value="sftp.sftpUsername"
        />
        <template v-if="sftp.sftpPassword">
          <FormulateInput
            :readonly="true"
            label="Password"
            type="text"
            :value="sftp.sftpPassword"
          />
          <p class="mt-2">
            We'll only show you the password once, so be sure to save it!
          </p>
        </template>
      </FormulateForm>
      <Button
        class="mt-10 w-full md:w-auto"
        variant="primary"
        @click="resetPassword"
      >
        Generate new password
      </Button>
      <Alert
        severity="info"
        heading="Need help setting up the export from your provider?"
        class="mt-10"
        :hide-close-button="true"
      >
        Check out our help guide
        <a href="https://docs.useworkshop.com/how-to-setup-sftp-integration"
          >here</a
        >
      </Alert>
    </FeatureGate>

    <FeatureGate feature-disabled="sftp_syncing" feature-type="platform">
      <ContentPlaceholder class="my-10">
        <template #title>Complete SFTP setup</template>
        <template #message>
          Workshop needs some more information to complete the SFTP setup. Reach
          out to us so we can get those steps taken care of!
        </template>

        <template #cta>
          <Button
            variant="primary"
            :disabled-visually="isLoading"
            href="mailto:help@useworkshop.com"
          >
            Contact Workshop
          </Button>
        </template>
      </ContentPlaceholder>
    </FeatureGate>
  </div>
  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Alert from 'app/core/alert/Alert.vue';
import Button from 'app/core/button/Button.vue';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';
import Title from 'app/core/title/Title.vue';
import FeatureGate from 'app/utilities/feature-gate/FeatureGate.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';

export default {
  name: 'ManageSftp',

  components: {
    Alert,
    AppPlaceholder,
    Button,
    ContentPlaceholder,
    LoadingIcon,
    Title,
    FeatureGate,
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
    ]),
    ...mapGetters('app', {
      sftp: 'connectedData',
    }),
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected', 'updateConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },

    resetPassword() {
      this.updateConnected({ type: this.app.type, params: {} });
    },
  },
};
</script>
