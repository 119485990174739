<template>
  <LoadingIcon
    v-if="!isInitialized || isLoading"
    class="mx-auto my-20 w-8 text-blue-500"
  />
  <div v-else-if="isActive">
    <ContentPlaceholder v-if="!deliveryMethod" class="my-10">
      <template #message>
        It looks like something went wrong with your
        {{ app.name }} connection</template
      >
      <template #cta>
        <Button
          variant="secondary"
          color="danger"
          :disabled="isSaving"
          @click="handleAddApp"
        >
          Reconfigure App
        </Button>
      </template>
    </ContentPlaceholder>
    <ManageSlackForm v-else :slack="slack" :delivery-method="deliveryMethod" />
  </div>

  <AppPlaceholder
    v-else
    :app="app"
    :is-loading="isSaving"
    @add-app="handleAddApp"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import Button from 'app/core/button/Button.vue';
import ContentPlaceholder from 'app/core/content-placeholder/ContentPlaceholder.vue';
import AppPlaceholder from 'app/views/apps/app-placeholder/AppPlaceholder.vue';

import ManageSlackForm from './ManageSlackForm.vue';

export default {
  name: 'ManageSlack',

  components: {
    AppPlaceholder,
    LoadingIcon,
    ManageSlackForm,
    ContentPlaceholder,
    Button,
  },

  computed: {
    ...mapGetters('app', [
      'app',
      'isActive',
      'isLoading',
      'isSaving',
      'isInitialized',
      'deliveryMethod',
    ]),
    ...mapGetters('app', {
      slack: 'connectedData',
    }),
  },

  created() {
    this.getConnected({ type: this.app.type });
  },

  methods: {
    ...mapActions('app', ['getConnected', 'addConnected']),

    handleAddApp() {
      this.addConnected({ type: this.app.type });
    },
  },
};
</script>
