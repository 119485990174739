import MegaphoneIcon from '@workshop/baja/assets/icons/icon-megaphone.svg';
import TeamsIcon from '@workshop/baja/assets/icons/icon-teams.svg';

/**
 * Stores app-specific settings defined in app modules
 * The keys are the app name ('email', 'slack', etc.) and the values are app settings
 *
 * Settings for all apps:
 * - type: 'delivery' or 'data'
 * - label: display name
 * - core: if true, the app is not displayed in the app library and cannot be turned off
 *
 * Settings specific to library (non-core) apps:
 * - manageComponent: app settings UI for the app manage page
 * - indexPath: rails route function for the app index APIs (e.g. '/connected-apps/slacks')
 * - path: rails route function for the app detail APIs (e.g. '/connected-apps/slacks/xxxx')
 *
 * Settings specific to delivery channel apps:
 * - previewComponent: displays a send preview in the composer
 * - allowFeaturedImage: enables "featured image" field in composer & preview
 * - allowCustomMessage: enables "custom message" field in composer & preview
 * - customMessageMaxLength: max character count for custom message input
 *
 * Settings specific to data sync apps:  NONE
 */
const appTypes = new Map();

export function registerApp(config) {
  appTypes.set(config.name, config);
}

export function isApp(name) {
  return appTypes.has(name);
}

export function getApp(name) {
  if (!appTypes.has(name)) {
    // this is a fatal error since we should always be dealing with a known set of apps
    throw new Error(`Invalid app name: "${name}"`);
  }
  return appTypes.get(name);
}

export function getAppNames(includeCore = false) {
  const names = Array.from(appTypes.keys());
  return includeCore ? names : names.filter((name) => !appTypes.get(name).core);
}

export function getDeliveryChannelNames(includeCore = false) {
  return getAppNames(includeCore).filter(
    (name) => appTypes.get(name).type === 'delivery'
  );
}

export function getDataSyncNames(includeCore = false) {
  return getAppNames(includeCore).filter(
    (name) => appTypes.get(name).type === 'data'
  );
}

export const APP_CATEGORIES = {
  delivery: {
    name: 'Delivery channel',
    filterName: 'Delivery channels',
    icon: MegaphoneIcon,
  },

  data: {
    name: 'Data',
    filterName: 'Data syncing',
    icon: TeamsIcon,
  },
};
