/* eslint-disable no-param-reassign */
export default {
  name: 'fullWidth',
  enabled: true,
  phase: 'write',
  requires: ['applyStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
  effect: ({ state }) => {
    if (state.rects) {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    }
  },
};
