var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g({tag:"component",class:[
    'button',
    _vm.prefixClass(_vm.size),
    _vm.prefixClass(_vm.color),
    _vm.prefixClass(_vm.variant),
    {
      'button--disabled': _vm.disabled,
      'button--aria-disabled': _vm.disabledVisually,
      'button--circle': _vm.circle,
    } ],attrs:{"href":!_vm.disabled && _vm.href,"target":_vm.href && _vm.external ? '_blank' : undefined,"rel":_vm.href && _vm.external ? 'noreferrer' : undefined,"type":_vm.href ? undefined : _vm.type,"disabled":_vm.disabled,"disabled-visually":_vm.disabledVisually || _vm.isLoading,"aria-disabled":_vm.disabledVisually || _vm.isLoading},on:{"click":_vm.handleClick}},_vm.filteredListeners),[_c('FadeTransition',{attrs:{"enter-duration":"duration-300","leave-duration":"duration-75","enter-delay":"delay-500","enter-timing":"ease-in-out","leave-timing":"ease-in-out"}},[(_vm.isLoading)?_c('span',{staticClass:"button__loader"},[_c('LoadingIcon',{staticClass:"size-full"})],1):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"button__content",class:[_vm.hasAppendOrPrepend ? 'flex items-center child:shrink-0' : '']},[_vm._t("prepend"),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("append")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }