import axios from 'axios';
import { isFunction, isString } from 'lodash-es';

import LoadingIcon from '@workshop/baja/assets/icons/icon-loading.svg';
import SearchIcon from '@workshop/baja/assets/icons/icon-search.svg';
import ListIcon from '@workshop/baja/assets/icons/icon-streams-mini.svg';
import DeleteIcon from '@workshop/baja/assets/icons/icon-x.svg';
import FadeTransition from 'app/brand/transitions/FadeTransition.vue';
import Avatar from 'app/core/avatar/Avatar.vue';
import Badge from 'app/core/badge/Badge.vue';
import BadgeGroup from 'app/core/badge-group/BadgeGroup.vue';
import AutocompleteInput, {
  AutocompleteInputProps,
} from 'app/formulate/autocomplete-input/AutocompleteInput.vue';
import paginationInfiniteMixin from 'mixins/pagination-infinite-mixin';
import {
  getOmnisearchColor,
  getOmnisearchIcon,
  getOmnisearchLabel,
  getOmnisearchSource,
} from 'models/omnisearch';

export default {
  name: 'Multiselect',

  components: {
    AutocompleteInput,
    Avatar,
    Badge,
    BadgeGroup,
    DeleteIcon,
    FadeTransition,
    ListIcon,
    LoadingIcon,
    SearchIcon,
  },

  props: {
    badgeColorFunction: {
      type: Function,
      default: undefined,
    },
    badgeIconFunction: {
      type: Function,
      default: undefined,
    },
    badgeIconSizeFunction: {
      type: Function,
      default: undefined,
    },
    context: {
      type: Object,
      required: true,
    },
    defaultExpand: {
      type: Boolean,
      default: false,
    },
    behavior: {
      type: String,
      default: 'tag',
    },
    /**
     * Enables the 'interactive' prop on returned items/badges in the multiselect
     * Can be true to make all returned badges interactive, or a function that
     * returns a boolean that can be used to filter items for interactivity
     */
    interactiveItems: {
      type: [Boolean, Function],
      default: false,
    },
    searchEndpoint: {
      type: String,
      default: undefined,
    },
    searchFunction: {
      type: Function,
      default: undefined,
    },
    searchParams: {
      type: Object,
      default: undefined,
    },
    searchQuery: {
      type: String,
      default: undefined,
    },
    searchVerb: {
      type: String,
      default: 'get',
    },
    showWarningFunction: {
      type: Function,
      default: undefined,
    },
    syncLocked: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (val) => AutocompleteInputProps.variants.includes(val),
    },
  },

  mixins: [paginationInfiniteMixin({ isLoadingPropName: 'isLoading' })],

  data() {
    return {
      options: [],
      editing: false,
      isLoading: false,
      hasFocus: false,
    };
  },

  computed: {
    model() {
      return this.context.model;
    },
  },

  mounted() {
    this.editing = this.context.slotProps.component.editing;
  },

  methods: {
    isFunction,
    getOmnisearchLabel,
    getOmnisearchColor,
    getOmnisearchIcon,
    getOmnisearchSource,

    getBadgeColorFunction(item) {
      if (isFunction(this.badgeColorFunction)) {
        return this.badgeColorFunction(item);
      }

      return this.showWarnIcon(item) ? 'gray-dark' : getOmnisearchColor(item);
    },

    getBadgeIconFunction(item) {
      if (isFunction(this.badgeIconFunction)) {
        return this.badgeIconFunction(item);
      }

      return getOmnisearchIcon(item);
    },

    getBadgeIconSizeFunction(item) {
      if (isFunction(this.badgeIconSizeFunction)) {
        return this.badgeIconSizeFunction(item);
      }

      return undefined;
    },

    showWarnIcon(item) {
      return !!(
        isFunction(this.showWarningFunction) && this.showWarningFunction(item)
      );
    },

    handleScrollInfo(scrollInfo) {
      const nextPageInfo = this.processInfiniteScrollInfo(scrollInfo);

      if (nextPageInfo) {
        this.handleInputChange(nextPageInfo.query, nextPageInfo.page);
      }
    },

    handleSelected(item) {
      if (!this.model) {
        this.context.model = [];
      }
      // this if statement is to prevent the issue that occurred in https://linear.app/useworkshop/issue/WRK-2592/fff-can-add-duplicate-user-when-sending-test-emails
      if (this.context.model.includes(item)) return;

      this.context.model.push(item);

      this.options = [];

      this.$emit('change', this.context.model);
    },

    async handleInputChange(query, page = 1) {
      this.processInputChangeInfiniteQuery(query);

      if (isFunction(this.searchFunction)) {
        this.options = await this.searchFunction(query, {
          searchParams: this.searchParams,
          searchQuery: this.searchQuery,
          searchVerb: this.searchVerb,
          searchEndpoint: this.searchEndpoint,
        });
      } else if (this.searchEndpoint) {
        const isPost = this.searchVerb === 'post';
        const params = {
          ...this.processInputChangeInfiniteParams(page),
          ...this.searchParams,
        };

        if (this.model) {
          params.excluded = this.model.map((item) => {
            return isString(item) ? item : item.id;
          });
        }

        if (!this.searchQuery) {
          params.q = query;
        } else if (isPost) {
          params.q = { [this.searchQuery]: query };
        } else if (this.searchQuery) {
          params[`q[${this.searchQuery}]`] = query;
        }

        this.isLoading = true;
        const resp = isPost
          ? await axios.post(this.searchEndpoint, params)
          : await axios.get(this.searchEndpoint, { params });
        this.options = this.processInputChangeInfiniteOptions(
          resp.data,
          this.options
        );
        this.isLoading = false;
      } else {
        this.options = [];
      }

      this.$emit('query-count', this.options?.length || 0, query);
    },

    handleRemove(badge) {
      if (isString(badge)) {
        this.context.model = this.context.model.filter((i) => i !== badge);
      } else if (typeof badge === 'object') {
        this.context.model = this.context.model.filter(
          (i) => i.id !== badge.id
        );
      }

      this.$emit('change', this.context.model);
    },

    handleClick(badge) {
      this.$emit('item-click', badge);
    },
  },
};
