import PreviewMixin from 'mixins/preview-mixin';

import TeamsCard from './TeamsCard.vue';
import WebhookImage from './webhook.svg';

export default {
  name: 'PreviewTeams',

  mixins: [PreviewMixin],

  components: { WebhookImage, TeamsCard },

  computed: {
    timestamp() {
      return this.updateDateTime.toFormat('t');
    },
  },
};
