<template>
  <thead>
    <tr :class="{ sorted: sortingInfo }">
      <th
        v-if="select"
        scope="col"
        class="cursor-pointer"
        :class="[{ select: select }, thClass]"
        @click="handleCheckCellClick"
        @click.stop
      >
        <FormulateInput
          v-model="internalSelectAll"
          type="checkbox"
          aria-label="Select all emails"
          @change="handleChange"
          @click.native.stop
        />
      </th>

      <th
        v-for="header in headers"
        :key="header.title"
        scope="col"
        role="columnheader"
        :aria-sort="isActiveSort(header) ? sortDirection : undefined"
        class="print:break-after-avoid"
        :class="[getAlignClass(header), thClass]"
      >
        <SortHeader
          v-if="sortingInfo && header.sort"
          :sorting-info="sortingInfo"
          :header="header"
          @sort-event="handleSort"
        >
          {{ header.title }}
        </SortHeader>
        <span v-else class="font-bold">
          {{ header.title }}
        </span>
        <Tooltip
          v-if="header.tooltip"
          :width="200"
          class="-mr-1 -mt-0.5 align-middle"
        >
          <template #overlay>
            <p>{{ header.tooltip }}</p>
          </template>
        </Tooltip>
      </th>

      <th v-if="menu" :class="{ menu: menu }" scope="col">
        <!-- Gear -->
      </th>
    </tr>
  </thead>
</template>

<script>
import SortHeader from 'app/core/sort-header/SortHeader.vue';
import Tooltip from 'app/core/tooltip/Tooltip.vue';

export const TableHeadProps = {
  alignments: ['left', 'center', 'right'],
};

export default {
  name: 'TableHead',

  components: {
    SortHeader,
    Tooltip,
  },

  props: {
    align: {
      type: String,
      required: false,
      default: 'left',
      validator: (value) => TableHeadProps.alignments.includes(value),
    },
    sortingInfo: {
      type: Object,
      required: false,
      default: undefined,
    },
    headers: {
      type: Array,
      required: true,
    },
    select: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
      required: false,
    },
    sync: {
      type: Boolean,
      default: false,
      required: false,
    },
    menu: {
      type: Boolean,
      default: false,
      required: false,
    },
    thClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      internalSelectAll: false,
    };
  },

  computed: {
    sortDirection() {
      return this.sortingInfo.sortDirection === 'asc'
        ? 'ascending'
        : 'descending';
    },
  },

  watch: {
    selectAll: {
      immediate: true,
      handler(newValue) {
        this.internalSelectAll = newValue;
      },
    },
  },

  methods: {
    getAlignClass(header) {
      const align = header.align || this.align;
      switch (align) {
        case 'right':
          return 'text-right';
        case 'center':
          return 'text-center';
        default:
          return 'text-left';
      }
    },

    handleCheckCellClick() {
      this.internalSelectAll = !this.internalSelectAll;
      this.handleChange();
    },

    handleChange() {
      this.$emit('select-all', this.internalSelectAll);
    },

    handleSort(sort) {
      this.$emit('sort-event', sort);
    },

    headerSortValue(header) {
      return header.sortColumn || header.title.toLowerCase();
    },

    isActiveSort(header) {
      return (
        this.sortingInfo &&
        this.sortingInfo.sortBy === this.headerSortValue(header)
      );
    },
  },
};
</script>
