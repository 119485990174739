/* eslint-disable no-param-reassign */
export default {
  name: 'maxWidth',
  enabled: true,
  phase: 'write',
  requires: ['applyStyles'],
  fn: ({ state, options }) => {
    state.styles.popper.maxWidth = `${options.maxWidth}px`;

    if (options.fullWidth) {
      state.styles.popper.minWidth = `${state.rects.reference.width}px`;
    }
  },
  effect: ({ state, options }) => {
    if (state.rects) {
      state.styles.popper.maxWidth = `${options.maxWidth}px`;
    }
  },
};
